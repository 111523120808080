import { EventEmitter, Injectable, TemplateRef } from "@angular/core";

Injectable()
export class CustomBackgroundService{
  private template: any;
  public templateEmitter = new EventEmitter();

  setTemplate(template: any) {
    this.template = template;
    this.templateEmitter.emit(this.template);
  }
}
import { Routes } from "@angular/router";
import { VideoScreenComponent } from "./components/video-screen/video-screen.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "association",
    pathMatch: "full",
  },
  {
    path: "association",
    loadChildren: async () =>
      (await import("./views/association/association.routes"))
        .associationRoutes,
  },
  {
    path: "video",
    component: VideoScreenComponent,
  },
];

export class AppRoutingModule {}

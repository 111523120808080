import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomBackgroundService } from 'src/app/services/custom-background.service';

@Component({
  selector: 'krd-custom-background',
  templateUrl: './custom-background.component.html',
  styleUrls: ['./custom-background.component.scss']
})
export class CustomBackgroundComponent implements OnInit, OnDestroy {
  template : any;
  subscription : Subscription;

  constructor(private customBackgroundService : CustomBackgroundService){}

  ngOnInit(): void {
    this.subscription = this.customBackgroundService.templateEmitter.subscribe((template : any) => {
      this.template = template;
    });
  }

  ngOnDestroy() : void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "convertSpeed", standalone: true })
export class ConvertSpeedPipe implements PipeTransform {
  transform(value: number): number {
    const units = localStorage.getItem("units");

    if (units === "imperial") {
      let formattedDistance = Math.round(value * 0.621371 * 10) / 10;
      return formattedDistance;
    } else {
      let formattedDistance = Math.round(value * 10) / 10;
      return formattedDistance;
    }
  }
}

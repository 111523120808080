
<button
  *ngIf="!linkHref"
  class="ripple-button container"
  #button 
  (click)="buttonClick($event)"
  [routerLink]="routerLink"
>
  <ng-content></ng-content>
  <span class="hover-background"></span>
  <span 
    [className]="'anim-span ' + (ripple? 'ripple' : '')"
    [ngStyle]="{'left.px': left, 'top.px': top}"
  >
  </span>
</button>

<a 
  *ngIf="linkHref"
  class="ripple-link container"
  #link 
  (click)="buttonClick($event)"
  [href]="linkHref"
  target="_blank"
>
  <ng-content></ng-content>
  <span class="hover-background"></span>
  <span 
    [className]="'anim-span ' + (ripple? 'ripple' : '')"
    [ngStyle]="{'left.px': left, 'top.px': top}"
  >
  </span>
</a>


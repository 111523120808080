import { Component, Input, OnInit } from '@angular/core';
import { Sticker } from 'src/app/models/sticker';

@Component({
  selector: 'krd-sticker-list',
  templateUrl: './sticker-list.component.html',
  styleUrls: ['./sticker-list.component.scss']
})
export class StickerListComponent {
  @Input() stickers : Sticker[] = [];
}

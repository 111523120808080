<div class="chart-container">
  <div
    class="d-flex mt-3 justify-content-center"
    *ngIf="data.equipmentType !== 'rower' || data.mode === 'COACHING'"
  >
    <div id="next-slope-container" class="align-items-center">
      <div class="text-center text-upper-chart">
        {{ "next-slope.1" | translate }}
        <strong>{{ nextSlope | number : "1.1-1" }}% </strong
        >{{ "next-slope.2" | translate }}
      </div>
      <div class="text-center ml-2" *ngIf="data.mode === 'COACHING'">
        <img
          width="13"
          src="./assets/img/Entrainement-RPM-white.svg"
          alt="icone de cadence"
          *ngIf="data.mode === 'COACHING' && data.equipmentType !== 'treadmill'"
        />
        <img
          width="18"
          src="./assets/img/Entrainement-km-heure-white.svg"
          alt="icone de vitesse"
          *ngIf="data.mode === 'COACHING' && data.equipmentType === 'treadmill'"
        />
      </div>
      <div
        class="text-center ml-2 text-upper-chart"
        *ngIf="data.mode === 'COACHING' && data.equipmentType !== 'treadmill'"
      >
        <strong
          >{{ nextValue }}
          <span>rpm</span>
        </strong>
      </div>
      <div
        class="text-center ml-2 text-upper-chart"
        *ngIf="data.mode === 'COACHING' && data.equipmentType === 'treadmill'"
      >
        <strong
          >{{ nextValue | convertSpeed }}
          <span>{{ nextValue | unitSpeed }}</span>
        </strong>
      </div>
      <div class="d-flex align-items-center ml-3 mr-2" id="yellow-pill">
        <div class="img-slope text-center ml-3">
          <img
            id="img-arrow"
            src="./assets/img/Entrainement-fleche-black.svg"
            alt="icone de flêche"
          />
        </div>
        <div
          class="next-slope-meter text-center text-upper-chart-black mr-2"
          *ngIf="data && data.mode === 'CHALLENGE'"
        >
          {{ nextDistance | toMileOrMeter }}{{ nextDistance | unitMeterMile }}
        </div>
        <div
          class="next-slope-meter text-center text-upper-chart-black mr-2"
          *ngIf="
            data && (data.mode === 'DISCOVERY' || data.mode === 'COACHING')
          "
        >
          {{ nextTime }}s
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" id="chart-container">
    <div
      class="row"
      *ngIf="data.equipmentType !== 'rower' || data.mode === 'COACHING'"
    >
      <div class="col">
        <div [chart]="chart"></div>
      </div>
    </div>
    <div
      class="row"
      *ngIf="data.equipmentType !== 'rower' || data.mode === 'COACHING'"
    >
      <div
        id="actual-slope-indicator"
        class="text-center arrow_box"
        [style.left.px]="slopeIndicatorPlacement"
      >
        {{ currentSlope | number : "1.1-1" }} %
        <div
          *ngIf="data.mode === 'COACHING' && data.equipmentType !== 'treadmill'"
          class="d-flex align-items-center justify-content-center"
        >
          {{ currentValue }} <span>rpm</span>
        </div>
        <div
          *ngIf="data.mode === 'COACHING' && data.equipmentType === 'treadmill'"
          class="d-flex align-items-center justify-content-center"
        >
          {{ currentValue | convertSpeed }}
          <span>{{ currentValue | unitSpeed }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

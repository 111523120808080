import { Component, Input, AfterViewInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { WebSocketService } from "../../services/web-socket.service";
import { TranslateModule } from "@ngx-translate/core";
import { ConvertSpeedPipe } from "src/app/pipe/ConvertSpeed.pipe";
import { ConvertSpeedRowerPipe } from "src/app/pipe/ConvertSpeedRower.pipe";
import { ToMileOrMeterPipe } from "src/app/pipe/to_mile_or_meter.pipe";
import { UnitMeterMilePipe } from "src/app/pipe/unit_meter_mile.pipe";
import { UnitSpeedPipe } from "src/app/pipe/unit_speed.pipe";
import { NgClass, NgFor, NgIf, NgStyle } from "@angular/common";

@Component({
  selector: "krd-podium",
  templateUrl: "./podium.component.html",
  styleUrls: ["./podium.component.scss"],
  imports: [
    TranslateModule,
    ConvertSpeedPipe,
    ConvertSpeedRowerPipe,
    ToMileOrMeterPipe,
    UnitMeterMilePipe,
    UnitSpeedPipe,
    NgFor,
    NgIf,
    NgClass,
    NgStyle,
  ],
  standalone: true,
})
export class PodiumComponent implements AfterViewInit, OnDestroy {
  @Input() appColor = "rgb(28, 132, 177)";
  @Input() data: any;
  @Input() videoOption = { mode: "CHALLENGE" };
  @Input() position: number;
  @Input() distanceFromOther: any[];
  @Input() modeCoaching: boolean;
  @Input() coachingIntervals: any[];
  @Input() timeSecond: any;
  @Input() currentSpeed: number;
  @Input() playerLength = 0;

  currentValue = 0;
  subscriptionEventCommand: Subscription;
  zoom = false;

  targetValue(val: number) {
    for (const coachingInterval of this.coachingIntervals) {
      if (this.timeSecond > coachingInterval.position) {
        this.currentValue = coachingInterval.value;
      }
    }
    if (val > this.currentValue + (this.currentValue * 10) / 100) {
      return "up";
    } else if (val < this.currentValue - (this.currentValue * 10) / 100) {
      return "down";
    } else {
      return "even";
    }
  }

  constructor(private socket: WebSocketService) {}

  ngOnDestroy(): void {
    if (this.subscriptionEventCommand) {
      this.subscriptionEventCommand.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.subscriptionEventCommand = this.socket.eventCommand.subscribe(
      (data) => {
        switch (data.command) {
          case "RANK:ZOOM":
            this.zoom = true;
            break;
          case "RANK:GLOBAL":
            this.zoom = false;
            break;
        }
      }
    );
  }
}

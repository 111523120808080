import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Sticker } from 'src/app/models/sticker';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { UAParser } from 'ua-parser-js';
import * as QRious from 'qrious';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'krd-association-home',
  templateUrl: './association-home.component.html',
  styleUrls: ['./association-home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AssociationHomeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('qr') qrImg : ElementRef<HTMLImageElement>;
  @ViewChild('tv') tvBackground : ElementRef<HTMLImageElement>;

  public qrCodeString: string = null;
  public qrCodeSize = 210;
  instructionStickers : Sticker[] = [{
    iconSrc: 'assets/icons/download.svg',
    roundNumber: 1,
    circleProgression: 25,
    translationId: 'association.home.instructions.download',
    message : '',
    size: 1,
    id : 1
  },
  {
    iconSrc: 'assets/icons/user.svg',
    roundNumber: 2,
    circleProgression: 50,
    translationId: 'association.home.instructions.create',
    message : '', 
    size: 1,
    id : 2
  },
  {
    iconSrc: 'assets/icons/connexion.svg',
    roundNumber: 3,
    circleProgression: 75,
    translationId: 'association.home.instructions.connect',
    message : '', 
    size: 1,
    id : 3
  },
  {
    iconSrc: 'assets/icons/qrcode.svg',
    roundNumber: 4,
    circleProgression: 100,
    translationId: 'association.home.instructions.activate',
    message : '', 
    size: 1,
    id : 4
  }];

  parser = new UAParser();
  browserCompatible: boolean;
  maintenance = false;
  subscriptions : Subscription[] = [];

  // For LG Device
  
  params = {
    challenge: true,
    discovery: true
  }

  constructor(private socket: WebSocketService,
    private route: ActivatedRoute,
    private translate : TranslateService,
    private langService : LanguageService) { }

  ngOnInit(): void {

    this.socket.reconnect(this.params);
    const browserName = this.parser.getBrowser().name.toLowerCase();
    if (browserName === 'chrome' || browserName === 'firefox' || browserName === 'chromium') {
        this.browserCompatible = true;
    } else {
        this.browserCompatible = false;
    }
    this.updateTranslations();
    this.subscriptions.push(this.langService.loadedLangEmitter.subscribe(x => {
      this.updateTranslations();
    }));
    this.maintenance = false;
    this.qrCodeString = this.generateId();
  }

  ngAfterViewInit(): void {
    this.socket.initSocket(this.qrCodeString);
    if (this.parser.getUA().includes('apple_tv')) {
      this.browserCompatible = true;
    }
    const qr = new QRious({
      element: this.qrImg.nativeElement,
      value: this.qrCodeString,
      level: 'M',
      size: this.qrCodeSize,
      padding: 0,
      backgroundAlpha: 0
    });

    this.route.queryParams.subscribe(params => {
      if(params) {
        console.log('we get the params')
        console.log(params)
        this.params.challenge = params?.challenge ? false : true
        this.params.discovery = params?.discovery ? false : true

        if(this.params.challenge == false) {
          console.log('we set to true')
          this.socket.isLG.next(true);
        }
      }      
    });
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private updateTranslations() : void {
    this.instructionStickers = 
      this.instructionStickers.map(x => (
        {
          ...x, 
          message: this.translate.instant(x.translationId)
        }
      ));
  }

  /**
   * Generates unique identifier for every channel
   * @return id
   */
  public generateId(): string {
    return Math.floor(100000 + Math.random() * 900000).toString();
  }
}



<div class="home w-100 h-100">

  <div class="title" [innerHTML]="'association.home.title' | translate"></div>
  <div class="subtitle" [innerHTML]="'association.home.sub-title' | translate"></div>

  <div class="instruction-list">
    <krd-sticker-list [stickers]="instructionStickers"></krd-sticker-list>
  </div>

  <div class="qr-code-section">
    <div class="qr-code-container">
      <img #qr class="qr-code" />
    </div>

    <div class="separator">{{ 'qrcode.or' | translate }}</div>

    <div class="text-code-box">
      <span class="code-label">{{ 'qrcode.code-word' | translate }}</span>
      <span class="code">{{ qrCodeString }}</span>
    </div>
  </div>
  

  <krd-custom-background-passer>
    <div class="tv-background" #tv>
      <div class="external-light"></div>
      <div class="external-shadow"></div>
      <div class="light"></div>
      <img src="assets/img/tv.png" class="tv" />
    </div>
  </krd-custom-background-passer>

</div>
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "toMileOrMeter", standalone: true })
export class ToMileOrMeterPipe implements PipeTransform {
  transform(value: number): number {
    const units = localStorage.getItem("units");

    if (units === "imperial") {
      if (value === 0) return 0;

      value = value * 3.28084;
      let formattedDistance = Math.round(value);

      if (value > 2640) {
        formattedDistance = Math.round((value / 5280) * 10) / 10;
      }

      return formattedDistance;
    } else {
      // metric
      if (value === 0) return 0;

      let formattedDistance = Math.round(value * 10) / 10;
      if (value > 1000 || value < -1000) {
        formattedDistance = Math.round((value / 1000) * 10) / 10;
      }
      return formattedDistance;
    }
  }
}

import { Component, OnInit, ViewChild, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WebSocketService } from 'src/app/services/web-socket.service';

@Component({
  selector: 'krd-gradient-feel',
  templateUrl: './gradient-feel.component.html',
  styleUrls: ['./gradient-feel.component.scss']
})
export class GradientFeelComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() data: any;

  subscription: Subscription;
  difficulty = 0;
  equipmentLevel;

  @ViewChild('progressBarRight') progressBarRight;
  @ViewChild('progressBarLeft') progressBarLeft;

  constructor(private socket: WebSocketService) { }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {
    if (this.data.isInteractif) {
      this.difficulty = 100;
    }
  }

  ngAfterViewInit() {
    this.subscription = this.socket.event.subscribe(res => {
      this.difficulty = res.difficulty_rate;
      this.equipmentLevel = res.equipment_level;
    });
  }

}

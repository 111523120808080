<div class="container classement pl-0">
    <div class="row classement-box align-items-center mb-3">
        <div class="col-sm-2 text-center px-0">
            <img id="img-classement" src="./assets/img/Entrainement-podium.svg" alt="icone podium">
        </div>
        <div class="col-sm-6 text-classement text-center px-0">
            {{ 'podium.attendees' | translate }}
        </div>
        <div class="col-sm-3 text-center px-0">
            <span class="grey-text">( </span>
            <span class="position-text" [style.color]="appColor" *ngIf="!modeCoaching"> {{position}} </span>
            <span class="white-text" *ngIf="!modeCoaching">/ {{ playerLength }}</span>
            <span class="white-text" *ngIf="modeCoaching">{{ playerLength }}</span>
            <span class="grey-text"> )</span>
        </div>
    </div>
    <div *ngFor="let player of data.players; let i = index">
        <ng-container *ngIf="zoom && i +1 < position + 4 && i +1 > position - 2 && player.type !== 'CONTRIBUTOR'">
            <div class="row detail-participant align-items-center">
                <div class="col-4 pr-0">
                    <img class="img-avatar" [src]="player.avatar_url" alt="image avatar" *ngIf="player.avatar_url">
                    <div class="position" *ngIf="player.type !== 'LOCAL' && !modeCoaching">{{i + 1}}</div>
                    <div class="position" [style.backgroundColor]="appColor" *ngIf="player.type === 'LOCAL' && !modeCoaching">{{i + 1}}</div>
                </div>
                <div class="col-8">
                    <div class="row">
                        <div class="col text-name">
                            {{player.username}}
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col white-text" *ngIf="!modeCoaching && videoOption.mode == 'CHALLENGE'" [ngClass]="{
                            'orange-color boldish': player.type === 'LOCAL' && currentSpeed < 0.8,
                            'green-color boldish': player.type === 'LOCAL' && currentSpeed >= 0.8,
                            'white-color': player.type !== 'LOCAL'
                        }">
                            <span *ngIf="player.speed && player.speed > 0 && videoOption.equipmentType !== 'rower'">{{player.speed | convertSpeed}} {{player.speed | unitSpeed}}</span>
                            <span *ngIf="player.speed && player.speed > 0 && videoOption.equipmentType === 'rower'">{{player.speed | convertSpeedRower}} <span class="little">/500m</span></span>
                        </div>
                        <div class="col white-text" *ngIf="modeCoaching || videoOption.mode != 'CHALLENGE'">
                            <span *ngIf="player.power && player.power !== -1">{{player.power}} w</span>
                        </div>
                        <div class="col-3 forward-distance px-1 text-center" *ngIf="distanceFromOther && distanceFromOther[i] !== 0 && !modeCoaching"><strong>{{distanceFromOther[i] | toMileOrMeter}}</strong>{{distanceFromOther[i] | unitMeterMile}}</div>
                        <!-- CADENCE -->
                        <div *ngIf="player.cadence">
                            <div *ngIf="modeCoaching && targetValue(player.cadence) ==='even' && videoOption.equipmentType !== 'treadmill'">
                                <span class="greenCadence">{{player.cadence}}</span> <span class="white-text"> rpm</span>
                            </div>
                            <div *ngIf="modeCoaching && targetValue(player.cadence) ==='up' && videoOption.equipmentType !== 'treadmill'">
                                <img class="img-cadence mr-1" src="./assets/img/Entrainement-coaching-flecheorange.svg" alt="arrow down icon">
                                <span class="orangeCadence">{{player.cadence}}</span> <span class="white-text"> rpm</span>
                            </div>
                            <div *ngIf="modeCoaching && targetValue(player.cadence) ==='down' && videoOption.equipmentType !== 'treadmill'">
                                <img class="img-cadence mr-1" src="./assets/img/Entrainement-coaching-flecherouge.svg" alt="arrow up icon">
                                <span class="redCadence">{{player.cadence}}</span> <span class="white-text"> rpm</span>
                            </div>
                        </div>
                        <div *ngIf="player.cadence === undefined">
                            <div *ngIf="modeCoaching && videoOption.equipmentType !== 'treadmill'">
                                <img class="img-cadence mr-1" src="./assets/img/Entrainement-coaching-flecherouge.svg" alt="arrow up icon">
                                <span class="redCadence">0</span> <span class="white-text"> rpm</span>
                            </div>
                        </div>
                        <!-- SPEED -->
                        <div *ngIf="player.speed">
                            <div *ngIf="modeCoaching && targetValue(player.speed) ==='even' && videoOption.equipmentType === 'treadmill'">
                                <span class="greenCadence">{{player.speed | convertSpeed}}</span>
                                <span class="white-text"> {{player.speed | unitSpeed}}</span>
                            </div>
                            <div *ngIf="modeCoaching && targetValue(player.speed) ==='up' && videoOption.equipmentType === 'treadmill'">
                                <img class="img-cadence mr-1" src="./assets/img/Entrainement-coaching-flecheorange.svg" alt="arrow down icon">
                                <span class="orangeCadence">{{player.speed | convertSpeed}}</span>
                                <span class="white-text"> {{player.speed | unitSpeed}}</span>
                            </div>
                            <div *ngIf="modeCoaching && targetValue(player.speed) ==='down' && videoOption.equipmentType === 'treadmill'">
                                <img class="img-cadence mr-1" src="./assets/img/Entrainement-coaching-flecherouge.svg" alt="arrow up icon">
                                <span class="redCadence">{{player.speed | convertSpeed}}</span>
                                <span class="white-text"> {{player.speed | unitSpeed}}</span>
                            </div>
                        </div>
                        <div *ngIf="player.speed === undefined">
                            <div *ngIf="modeCoaching && videoOption.equipmentType === 'treadmill'">
                                <img class="img-cadence mr-1" src="./assets/img/Entrainement-coaching-flecherouge.svg" alt="arrow up icon">
                                <span class="redCadence">0</span>
                                <span class="white-text"> {{0 | unitSpeed}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!zoom && player.type !== 'CONTRIBUTOR'">
            <div class="row detail-participant align-items-center">
                <div class="col-sm-3 m-0 p-0">
                    <img class="img-avatar" [src]="player.avatar_url" alt="image avatar" *ngIf="player.avatar_url">
                    <div class="position" *ngIf="player.type !== 'LOCAL' && !modeCoaching">{{i + 1}}</div>
                    <div class="position" [style.backgroundColor]="appColor" *ngIf="player.type === 'LOCAL' && !modeCoaching">{{i + 1}}</div>
                </div>
                <div class="col-sm-7 m-0 p-0">
                    <div class="row">
                        <div class="col text-name">
                            {{player.username}}
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col" *ngIf="!modeCoaching && videoOption.mode == 'CHALLENGE'" [ngClass]="{
                            'orange-color boldish': player.type === 'LOCAL' && currentSpeed < 0.8,
                            'green-color boldish': player.type === 'LOCAL' && currentSpeed >= 0.8,
                            'white-color': player.type !== 'LOCAL'
                        }">
                            <span *ngIf="player.speed && player.speed > 0 && videoOption.equipmentType !== 'rower'">
                                {{player.speed | convertSpeed}} {{player.speed | unitSpeed}}
                            </span>
                            <span *ngIf="player.speed && player.speed > 0 && videoOption.equipmentType === 'rower'">
                                {{player.speed | convertSpeedRower}}
                                <span class="little">/500m</span>
                            </span>
                        </div>
                        <div class="col white-text" *ngIf="modeCoaching || videoOption.mode != 'CHALLENGE'">
                            <span *ngIf="player.power && player.power !== -1">{{player.power}} w</span>
                        </div>
                        <div class="col-sm-4 forward-distance text-center" *ngIf="distanceFromOther && distanceFromOther[i] !== 0 && !modeCoaching"><strong>{{distanceFromOther[i] | toMileOrMeter}}</strong>{{distanceFromOther[i] | unitMeterMile}}</div>
                        <!-- CADENCE -->
                        <div *ngIf="player.cadence">
                            <div *ngIf="modeCoaching && targetValue(player.cadence) ==='even' && videoOption.equipmentType !== 'treadmill'">
                                <span class="greenCadence">{{player.cadence}}</span> <span class="white-text"> rpm</span>
                            </div>
                            <div *ngIf="modeCoaching && targetValue(player.cadence) ==='up' && videoOption.equipmentType !== 'treadmill'">
                                <img class="img-cadence mr-1" src="./assets/img/Entrainement-coaching-flecheorange.svg" alt="arrow down icon">
                                <span class="orangeCadence">{{player.cadence}}</span> <span class="white-text"> rpm</span>
                            </div>
                            <div *ngIf="modeCoaching && targetValue(player.cadence) ==='down' && videoOption.equipmentType !== 'treadmill'">
                                <img class="img-cadence mr-1" src="./assets/img/Entrainement-coaching-flecherouge.svg" alt="arrow up icon">
                                <span class="redCadence">{{player.cadence}}</span> <span class="white-text"> rpm</span>
                            </div>
                        </div>
                        <div *ngIf="player.cadence === undefined">
                            <div *ngIf="modeCoaching && videoOption.equipmentType !== 'treadmill'">
                                <img class="img-cadence mr-1" src="./assets/img/Entrainement-coaching-flecherouge.svg" alt="arrow up icon">
                                <span class="redCadence">0</span> <span class="white-text"> rpm</span>
                            </div>
                        </div>

                        <!-- SPEED -->
                        <div *ngIf="player.speed">
                            <div *ngIf="modeCoaching && targetValue(player.speed) ==='even' && videoOption.equipmentType === 'treadmill'">
                                <span class="greenCadence">{{player.speed | convertSpeed}}</span> <span class="white-text"> {{player.speed | unitSpeed}}</span>
                            </div>
                            <div *ngIf="modeCoaching && targetValue(player.speed) ==='up' && videoOption.equipmentType === 'treadmill'">
                                <img class="img-cadence mr-1" src="./assets/img/Entrainement-coaching-flecheorange.svg" alt="arrow down icon">
                                <span class="orangeCadence">{{player.speed | convertSpeed}}</span> <span class="white-text"> {{player.speed | unitSpeed}}</span>
                            </div>
                            <div *ngIf="modeCoaching && targetValue(player.speed) ==='down' && videoOption.equipmentType === 'treadmill'">
                                <img class="img-cadence mr-1" src="./assets/img/Entrainement-coaching-flecherouge.svg" alt="arrow up icon">
                                <span class="redCadence">{{player.speed | convertSpeed}}</span> <span class="white-text"> {{player.speed | unitSpeed}}</span>
                            </div>
                        </div>
                        <div *ngIf="player.speed === undefined">
                            <div *ngIf="modeCoaching && videoOption.equipmentType === 'treadmill'">
                                <img class="img-cadence mr-1" src="./assets/img/Entrainement-coaching-flecherouge.svg" alt="arrow up icon">
                                <span class="redCadence">0</span> <span class="white-text"> {{0 | unitSpeed}} 4</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2 m-0 p-0 text-end">
                  <img src="./assets/img/peloton/anim_peloton.gif" alt="animation peloton" *ngIf="player.is_slipstream_active" width="30px" style="display: block; margin-left: auto; margin-right: auto;">
                </div>
            </div>
        </ng-container>
    </div>
</div>

import { Component, Input, OnInit, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'krd-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() sections: any;
    @Input() coachingIntervals: any;
    @Input() data: any;
    @Input() timeSecond: any;

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        if(event.target.innerWidth <= 1200) {
            this.chartHeight = 100;
        } else {
            this.chartHeight = 150;
        }
        console.log(event.target.innerWidth);
    }

    chart: Chart;
    subscription: Subscription;
    subscriptionEventCommand: Subscription;
    nextDistance = 0;
    nextTime = 0;
    nextSlope = 0;
    nextValue = 0;
    minElevation = 10000;
    minElevationPercent = 10000;
    maxElevation = 0;
    maxElevationPercent = 0;
    currentSlope = 0;
    currentValue = 0;
    slopeIndicatorPlacement = 0;
    chartWidth;
    extremeDistance = 1000;
    extremeTime = 120;
    zoom = false;
    lastZone = 0;

    chartHeight = 150;


    constructor(private socket: WebSocketService) { }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.subscriptionEventCommand) {
            this.subscriptionEventCommand.unsubscribe();
        }
    }

    ngOnInit() {

        if(window.innerWidth <= 1200) {
            this.chartHeight = 100;
        } else {
            this.chartHeight = 150;
        }

        let data = [];
        let zone = [];
        let line = [];
        if (this.sections && this.data.mode !== 'COACHING') {
            for (const section of this.sections) {
                // elevation min && max for Y min && max
                if (section.elevation < this.minElevation) {
                    this.minElevation = section.elevation;
                }
                if (section.elevation > this.maxElevation) {
                    this.maxElevation = section.elevation;
                }
                const index = this.sections.indexOf(section);
                let nextZone;
                if (this.data.mode === 'CHALLENGE') {
                    if (index !== this.sections.length - 1) {
                        // zone color apply until nextZone
                        nextZone = this.sections[index + 1].distance;
                    } else {
                        // max X
                        this.lastZone = section.distance;
                    }

                    if (index === 0) {
                        // first flag
                        data.push({
                            x: section.distance,
                            y: section.elevation,
                            marker: {
                                enabled: true,
                                symbol: 'url(./assets/img/start-flag-white.svg)',
                                width: 40,
                                height: 40
                            }
                        });
                    }

                    data.push([section.distance, section.elevation]);
                } else {
                    if (index !== this.sections.length - 1) {
                        // zone color apply until nextZone
                        nextZone = this.sections[index + 1].position;
                    } else {
                        // max X
                        this.lastZone = section.position;
                    }

                    if (index === 0) {
                        // first flag
                        data.push({
                            x: section.position,
                            y: section.elevation,
                            marker: {
                                enabled: true,
                                symbol: 'url(./assets/img/start-flag-white.svg)',
                                width: 40,
                                height: 40
                            }
                        });
                    }
                    data.push([section.position, section.elevation]);
                }

                if (section.slope <= -2) {
                    // blue
                    zone.push({ value: nextZone, color: '#3376AA' });
                } else if (section.slope > -2 && section.slope <= 2) {
                    // green
                    zone.push({ value: nextZone, color: '#4FC562' });
                } else if (section.slope > 2 && section.slope <= 5) {
                    // yellow
                    zone.push({ value: nextZone, color: '#FFDF42' });
                } else if (section.slope > 5 && section.slope <= 8) {
                    // orange
                    zone.push({ value: nextZone, color: '#E69524' });
                } else {
                    // red
                    zone.push({ value: nextZone, color: '#DE3C21' });
                }
            }
        }
        if (this.coachingIntervals && this.data.mode === 'COACHING') {
            for (const coachingInterval of this.coachingIntervals) {
                // elevation min && max for Y min && max
                if (coachingInterval.value < this.minElevation) {
                    this.minElevation = coachingInterval.value;
                }
                if (coachingInterval.percentage < this.minElevationPercent) {
                    this.minElevationPercent = coachingInterval.percentage;
                }
                if (coachingInterval.value > this.maxElevation) {
                    this.maxElevation = coachingInterval.value;
                }
                if (coachingInterval.percentage > this.maxElevationPercent) {
                    this.maxElevationPercent = coachingInterval.percentage;
                }
                const index = this.coachingIntervals.indexOf(coachingInterval);
                let nextZone;
                if (index !== this.coachingIntervals.length - 1) {
                    // zone color apply until nextZone
                    nextZone = this.coachingIntervals[index + 1].position;
                } else {
                    // max X
                    this.lastZone = coachingInterval.position;
                }

                if (index === 0) {
                    // first flag
                    data.push({
                        x: coachingInterval.position,
                        y: coachingInterval.value
                    });
                }
                if (coachingInterval.value === 0) {
                    coachingInterval.value = 2;
                }
                data.push([coachingInterval.position, coachingInterval.value]);
                line.push([coachingInterval.position, coachingInterval.percentage]);

                switch (coachingInterval.type) {
                    case 'WARM_UP':
                        zone.push({ value: nextZone, color: 'rgb(152,230,234)' });
                        break;
                    case 'COOL_DOWN':
                    case 'RECOVERY':
                    case 'STRETCHING':
                        zone.push({ value: nextZone, color: 'rgb(24,147,178)' });
                        break;
                    case 'MUSCLE_STREGTHENING':
                        zone.push({ value: nextZone, color: 'rgb(187,183,183)' });
                        break;
                    default:
                        zone.push({ value: nextZone, color: 'rgb(0,190,240)' });
                }
            }
        }

        if (this.data.mode === 'CHALLENGE') {
            // last flag
            data.push({
                x: this.sections[this.sections.length - 1].distance,
                y: this.sections[this.sections.length - 1].elevation,
                marker: {
                    enabled: true,
                    symbol: 'url(./assets/img/final-flag-white.svg)',
                    width: 40,
                    height: 40
                }
            });
        } else if (this.data.mode === 'DISCOVERY') {
            // last flag
            data.push({
                x: this.sections[this.sections.length - 1].position,
                y: this.sections[this.sections.length - 1].elevation,
                marker: {
                    enabled: true,
                    symbol: 'url(./assets/img/final-flag-white.svg)',
                    width: 40,
                    height: 40
                }
            });
        } else if (this.data.mode === 'COACHING') {
            // last flag
            data.push({
                x: this.coachingIntervals[this.coachingIntervals.length - 1].position,
                y: this.coachingIntervals[this.coachingIntervals.length - 1].value
            });
        }
        // create the chart
        if (this.data.mode !== 'COACHING') {
            this.chart = new Chart({
                chart: {
                    type: 'areaspline',
                    height: `${this.chartHeight}px`,
                    animation: false
                },
                plotOptions: {
                    areaspline: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } }
                },
                title: {
                    text: ''
                },
                credits: {
                    enabled: false
                },
                legend: {
                    enabled: false
                },
                xAxis: {
                    min: 0,
                    max: this.lastZone,
                    labels: {
                        enabled: false,

                    },
                    visible: false
                },
                yAxis: {
                    min: this.minElevation,
                    max: this.maxElevation,
                    labels: {
                        enabled: false
                    },
                    title: {
                        text: ''
                    },
                    visible: false
                },
                series: [
                    {
                        type: undefined,
                        data: data,
                        zoneAxis: 'x',
                        zones: zone
                    }
                ]
            });
        } else { // coaching
            this.chart = new Chart({
                chart: {
                    height: `${this.chartHeight}px`,
                    styledMode: false
                },
                title: {
                    text: ''
                },
                credits: {
                    enabled: false
                },
                legend: {
                    enabled: false
                },
                xAxis: {
                    min: 0,
                    max: this.lastZone,
                    labels: {
                        enabled: false,

                    },
                    visible: false
                },
                yAxis: [{
                    min: this.minElevation,
                    max: this.maxElevation,
                    labels: {
                        enabled: false
                    },
                    title: {
                        text: ''
                    },
                    visible: false
                },
                {
                    min: this.minElevationPercent,
                    max: this.maxElevationPercent,
                    labels: {
                        enabled: false
                    },
                    title: {
                        text: ''
                    },
                    visible: false
                }
                ],
                series: [
                    {
                        type: 'area',
                        data: data,
                        zoneAxis: 'x',
                        zones: zone,
                        step: 'left',
                        lineWidth: 1,
                        yAxis: 0
                    },
                    {
                        type: 'line',
                        data: line,
                        step: 'left',
                        lineWidth: 1,
                        color: 'rgb(242,141,181)',
                        marker: {
                            enabled: false
                        },
                        yAxis: 1
                    }
                ]
            });
        }
    }

    ngAfterViewInit() {
        if (this.data.mode === 'CHALLENGE') {
            if (this.data.length < this.extremeDistance) {
                this.extremeDistance = this.data.length;
            }
        } else if (this.data.mode === 'DISCOVERY' || this.data.mode === 'COACHING') {
            if (this.data.duration < this.extremeTime) {
                this.extremeTime = this.data.duration;
            }
        }

        this.subscription = this.socket.event.subscribe(res => {
            if (!environment.production) {
                // console.table(res);
            }
            if (this.data.mode === 'CHALLENGE') {
                if (res.distance && this.chart.ref) {
                    this.chartWidth = this.chart.ref.axes[0].chart.chartWidth;
                    this.chartWidth = this.chartWidth - 22;
                    // set next zone slope
                    for (const section of this.sections) {
                        if (res.distance > section.distance) {
                            this.currentSlope = section.slope;
                        }
                        if (res.distance < section.distance) {
                            this.nextDistance = section.distance - res.distance;
                            this.nextSlope = section.slope;
                            break;
                        }
                    }
                    // set extreme on chart
                    if (this.zoom) {
                        if (
                            res.distance && res.distance > (this.extremeDistance / 2)
                            && res.distance + (this.extremeDistance / 2) < this.data.length
                        ) {
                            this.chart.ref.axes[0].setExtremes(
                                res.distance - (this.extremeDistance / 2), res.distance + (this.extremeDistance / 2)
                            );
                            this.slopeIndicatorPlacement = this.chartWidth / 2;
                        } else if (res.distance && res.distance + (this.extremeDistance / 2) > this.data.length) {
                            const lastSegment = this.data.length - this.extremeDistance / 2;
                            this.chart.ref.axes[0].setExtremes(this.data.length - (this.extremeDistance / 2), this.data.length);
                            this.slopeIndicatorPlacement = ((res.distance - lastSegment) / (this.extremeDistance / 2)) * this.chartWidth;
                        } else {
                            this.chart.ref.axes[0].setExtremes(0, this.extremeDistance);
                            this.slopeIndicatorPlacement = (res.distance / (this.extremeDistance / 2)) * (this.chartWidth / 2);
                        }
                    } else {
                        this.chart.ref.axes[0].setExtremes(0, this.lastZone);
                        this.slopeIndicatorPlacement = ((res.distance / this.lastZone) * this.chartWidth) - 5;
                    }
                }
            } else if (this.data.mode === 'DISCOVERY') {
                // Mode discovery
                if (this.timeSecond && this.chart.ref) {
                    this.chartWidth = this.chart.ref.axes[0].chart.chartWidth;
                    this.chartWidth = this.chartWidth - 22;
                    // set next zone slope
                    for (const section of this.sections) {
                        if (this.timeSecond > section.position) {
                            this.currentSlope = section.slope;
                        }
                        if (this.timeSecond < section.position) {
                            this.nextTime = Math.round(section.position - this.timeSecond);
                            this.nextSlope = section.slope;
                            break;
                        }
                    }
                    // set extreme on chart
                    if (this.zoom) {
                        if (
                            this.timeSecond && this.timeSecond  > (this.extremeTime / 2)
                            && this.timeSecond  + (this.extremeTime / 2) < this.data.duration
                        ) { // second part
                            this.chart.ref.axes[0].setExtremes(
                                this.timeSecond  - (this.extremeTime / 2), this.timeSecond  + (this.extremeTime / 2)
                            );
                            this.slopeIndicatorPlacement = this.chartWidth / 2;
                        } else if (this.timeSecond  && this.timeSecond  + (this.extremeTime / 2) > this.data.duration) { // last part
                            const lastSegment = this.data.duration - this.extremeTime / 2;
                            this.chart.ref.axes[0].setExtremes(this.data.duration - (this.extremeTime / 2), this.data.duration);
                            this.slopeIndicatorPlacement = ((this.timeSecond - lastSegment) / (this.extremeTime / 2)) * this.chartWidth;
                        } else { // first part
                            this.chart.ref.axes[0].setExtremes(0, this.extremeTime);
                            this.slopeIndicatorPlacement = (this.timeSecond  / (this.extremeTime / 2)) * (this.chartWidth / 2);
                        }
                    } else {
                        this.chart.ref.axes[0].setExtremes(0, this.lastZone);
                        this.slopeIndicatorPlacement = ((this.timeSecond  / this.lastZone) * this.chartWidth) - 5;
                    }
                }
            } else if (this.data.mode === 'COACHING') {
                // Mode coaching
                if (this.timeSecond && this.chart.ref) {
                    this.chartWidth = this.chart.ref.axes[0].chart.chartWidth;
                    this.chartWidth = this.chartWidth - 22;
                    // set next zone slope
                    for (const coachingInterval of this.coachingIntervals) {
                        if (this.timeSecond > coachingInterval.position) {
                            this.currentSlope = coachingInterval.percentage;
                            this.currentValue = coachingInterval.value;
                        }
                        if (this.timeSecond < coachingInterval.position) {
                            this.nextTime = Math.round(coachingInterval.position - this.timeSecond);
                            this.nextSlope = coachingInterval.percentage;
                            this.nextValue = coachingInterval.value;
                            break;
                        }
                    }
                    // set extreme on chart
                    if(this.zoom) {
                        if (this.timeSecond && this.timeSecond > (this.extremeTime / 2) && this.timeSecond + (this.extremeTime / 2) < this.data.duration) { // second part
                            this.chart.ref.axes[0].setExtremes(this.timeSecond - (this.extremeTime / 2), this.timeSecond + (this.extremeTime / 2));
                            this.slopeIndicatorPlacement = (this.chartWidth / 2) - 5;
                        } else if (this.timeSecond && this.timeSecond + (this.extremeTime / 2) > this.data.duration) { // last part
                            let lastSegment = this.data.duration - this.extremeTime / 2;
                            this.chart.ref.axes[0].setExtremes(this.data.duration - (this.extremeTime / 2), this.data.duration);
                            this.slopeIndicatorPlacement = (((this.timeSecond - lastSegment) / (this.extremeTime / 2)) * this.chartWidth) -5;
                        } else { // first part
                            this.chart.ref.axes[0].setExtremes(0, this.extremeTime);
                            this.slopeIndicatorPlacement = ((this.timeSecond / (this.extremeTime / 2)) * (this.chartWidth / 2)) - 5;
                        }
                    } else {
                        this.chart.ref.axes[0].setExtremes(0, this.lastZone);
                        this.slopeIndicatorPlacement = ((this.timeSecond / this.lastZone) * this.chartWidth) - 5;
                    }
                }
            }
        });

        this.subscriptionEventCommand = this.socket.eventCommand.subscribe(data => {
            // console.log('command received', data);
            switch (data.command) {
                case 'CHART:ZOOM':
                    this.zoom = true;
                    break;
                case 'CHART:GLOBAL':
                    this.zoom = false;
                    break;
            }
        });
    }
}

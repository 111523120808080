import { Injectable, EventEmitter } from "@angular/core";
import { io } from "socket.io-client";
import { Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { HttpClient } from "@angular/common/http";
import { UAParser } from "ua-parser-js";
import * as uuid from "uuid";
import { environment } from "../../environments/environment";
import { BehaviorSubject } from "rxjs";

export interface IDevice {
  device: string;
  device_os: string;
  device_name: string;
  browser: string;
  browser_version: string;
  browser_id: string;
  features: ParamsHttp;
}

interface ParamsHttp {
  challenge: boolean;
  discovery: boolean;
}

@Injectable()
export class WebSocketService {
  event = new EventEmitter();
  eventCommand = new EventEmitter();
  parser = new UAParser();
  public device: IDevice = {
    device: "",
    device_os: "",
    device_name: "",
    browser: "",
    browser_version: "",
    browser_id: "",
    features: {
      challenge: true,
      discovery: true,
    },
  };

  params: ParamsHttp;
  isLG = new BehaviorSubject(false);

  public videoData;

  public socketData: any = null;

  //public socket = io('https://remote.kinomap.com:4000');
  public socket = io(environment.middlewareUrl);

  /**
   * Constructor - injecting services
   *
   * @param router
   * @param http
   * @param deviceDetector
   */
  constructor(
    private router: Router,
    private http: HttpClient,
    private deviceDetector: DeviceDetectorService
  ) {}

  /**
   * Method called when we need to reconnect to the server in case of destroying connection
   */
  public reconnect(params: ParamsHttp) {
    if (!this.params) {
      this.params = params;
    }
    this.socket.disconnect();
    //this.socket = io('https://remote.kinomap.com:4000');
    this.socket = io(environment.middlewareUrl);
  }

  /**
   * Disconnect and emits the disconnect message
   */
  public disconnect() {
    this.socket.emit("web-disconnect");
  }

  /**
   * set device type
   * @returns {string}
   */
  public switchDevice(): string {
    if (this.deviceDetector.isTablet()) {
      return "tablet";
    }
    if (this.deviceDetector.isDesktop()) {
      return "desktop";
    }
    if (this.deviceDetector.isMobile()) {
      return "mobile";
    }
  }

  /**
   * set device and browser data to object device
   * @param deviceInfo
   */
  public setDevice(deviceInfo: any): any {
    this.device.device = this.switchDevice();
    this.device.device_os = this.parser.getOS().version;
    this.device.device_name = this.parser.getOS().name;
    this.device.browser = this.parser.getBrowser().name;
    this.device.browser_version = this.parser.getBrowser().version;
    this.device.browser_id = localStorage.getItem("browser_id");
    this.device.features = this.params;
    return this.device;
  }

  /**
   * Init socket connection and emits handshake, even listen for predefined events
   * @param qrCode
   */
  public initSocket(qrCode: string) {
    if (localStorage.getItem("browser_id") === null) {
      localStorage.setItem("browser_id", uuid.v4());
    }
    this.socket.on("connect", () => {
      // listen on handshake - called when someone connected to room
      this.socket.on("handshake", (data, ack) => {
        if (data.units) {
          localStorage.setItem("units", data.units);
        }
        let devices = JSON.parse(localStorage.getItem("device_id"));
        if (devices && !devices.includes(data.device_id)) {
          devices.push(data.device_id);
          localStorage.setItem("device_id", JSON.stringify(devices));
        }
        if (!devices) {
          localStorage.setItem("device_id", JSON.stringify([data.device_id]));
        }
        this.socketData = data;
        this.router.navigate(["/association", "connected"]);
        ack({ success: true, id: data.id });
        this.socket.emit("registerDevice", {
          device_id: localStorage.getItem("device_id"),
          browser_id: localStorage.getItem("browser_id"),
        });
      });

      // register yourself into room with handshake emit
      this.socket.emit(
        "handshake",
        {
          type: "web",
          web_id: qrCode,
          browser: this.setDevice(this.deviceDetector.getDeviceInfo()),
          name: this.device,
          device_id: localStorage.getItem("device_id"),
          features: this.params,
        },
        (data) => {
          // video load
          this.socket.on("video_load", (dataVideo, ack) => {
            this.videoData = dataVideo;
            this.router.navigate(["/", "video"]);
            ack({ success: true });
          });
          // Disconnect
          this.socket.on("mobile_disconnect", (dataDisconnect) => {
            if (this.params.challenge == false) {
              this.router.navigate(["/association"], {
                queryParams: { challenge: false },
              });
            } else {
              this.router.navigate(["/association"]);
            }
          });
          // data
          this.socket.on("broadcaster_data", (data) => {
            this.event.emit(data);
          });
        }
      );
      this.socket.on("disconnect", (dataDisconnect) => {
        if (this.params.challenge == false) {
          this.router.navigate(["/association"], {
            queryParams: { challenge: false },
          });
        } else {
          this.router.navigate(["/association"]);
        }
      });
      this.socket.on("dissociate", (dataDissociate) => {
        if (dataDissociate.browser_id === localStorage.getItem("browser_id")) {
          let devices = JSON.parse(localStorage.getItem("device_id"));
          if (devices && devices.includes(dataDissociate.device_id)) {
            const index = devices.indexOf(dataDissociate.device_id);
            devices.splice(index, 1);
            localStorage.setItem("device_id", JSON.stringify(devices));

            if (this.params.challenge == false) {
              this.router.navigate(["/association"], {
                queryParams: { challenge: false },
              });
            } else {
              this.router.navigate(["/association"]);
            }
          }
        }
      });
      this.socket.on("sendCommand", (data, ack) => {
        this.eventCommand.emit(data);
        if (
          data.command.includes("RANK") ||
          data.command.includes("MAP") ||
          data.command.includes("CHART")
        ) {
          ack({ success: true, message: "stateChanged" });
        } else if (data.command.includes("MUTE")) {
          ack({ success: true, message: "soundChanged" });
        }
      });
      this.socket.on("video_stop", (data, ack) => {
        ack({ success: true, id: data.id });
        this.router.navigate(["/association", "connected"]);
      });
    });
  }

  /**
   * Emits current time of playing
   * @param time
   * @param speed
   */
  public emitTimeChange(time, speed) {
    this.socket.emit("video_time", {
      id: this.videoData.id,
      time: time,
      speed: speed,
    });
  }

  /**
   * Emits a message when video ends
   */
  public emitVideoEnd() {
    this.socket.emit("video_end", {
      id: this.videoData.id,
    });
  }
}

<div class="association w-100 h-100">
  <div class="background w-100 h-100">

    <krd-custom-background>

    </krd-custom-background>
  </div>

  <div class="content">

    <div class="kinomap-screen-logo">
      <img src="assets/img/kinomap-screen-logo.svg" />
    </div>

    <div class="instruction-content">
      <router-outlet></router-outlet>
    </div>

    <div class="app-links">
      <rp-btn class="kinomap-logo-button mr-20" [linkHref]="'https://www.kinomap.com'">
        <img src="assets/icons/kinomap-black.svg"/>
      </rp-btn>

      <rp-btn class="app-link-button mr-20" [linkHref]="'https://apps.apple.com/fr/app/kinomap/id611177969'">
        <img src="assets/icons/app-store.svg"/>
      </rp-btn>

      <rp-btn class="app-link-button mr-20" [linkHref]="'https://play.google.com/store/apps/details?id=com.kinomap.training&hl=fr&gl=US'">
        <img src="assets/icons/android.svg"/>
      </rp-btn>

    </div>
    <div class="qr-code"></div>


  </div>

  <krd-lang class="language-selector"></krd-lang>

  <div class="footer">

  </div>

</div>

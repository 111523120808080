import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { BrowserService } from './services/browser.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language.service';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {

    constructor(
        private browserService: BrowserService,
        translate: TranslateService,
        private languageService: LanguageService
    ) {
        translate.addLangs(['en-EN', 'fr-FR', 'de-DE']);
        const observable = translate.use((this.languageService.get()) ? this.languageService.get() : 'en-EN');
        this.languageService.listenToLangLoading(observable);
    }

    title = 'kinomap-remote-display';
    @HostListener('window:focus', ['$event'])
    onFocus(event: FocusEvent): void {
        this.browserService.setFocused();
    }

    @HostListener('window:blur', ['$event'])
    onBlur(event: FocusEvent): void {
        this.browserService.setBlur();
    }
}

<div class="nav-lang">
    <div ngbDropdown display="dynamic" placement="bottom-left">
        <a class="nav-link" ngbDropdownToggle role="button">
            <img class="flag-icon" src="./assets/img/flags/{{ currentLanguage }}-s.svg" height="22">
            <div class="label">{{ getLanguageFirstCharacters(currentLanguage) }}</div>
            <img class="dropdown-icon" src="assets/icons/chevron-down.svg" />
        </a>
        <div ngbDropdownMenu class="dropdown-language text-left">
            <a ngbDropdownItem class="nav-link" *ngFor="let language of allAvailableLanguages" (click)="switchLang(language)">
                <img class="flag-icon" src="./assets/img/flags/{{ language }}-s.svg" height="22">
                <div class="label">{{ getLanguageFirstCharacters(language) }}</div>
            </a>
        </div>
    </div>
</div>
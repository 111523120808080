import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toKm' })
export class ToKmPipe implements PipeTransform {

    transform(value: number): number {
        let valueString;
        if (value > 1000 || value < -1000) {
            value = value / 1000;
            valueString = (Math.round(value * 10)/10).toFixed(1);
            return valueString;
        } else {
            return value;
        }

    }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'rp-btn',
  templateUrl: './ripple-button.component.html',
  styleUrls: ['./ripple-button.component.scss'],
})
export class RippleButtonComponent {
  @Output() click = new EventEmitter();
  @ViewChild('button') button : ElementRef<HTMLButtonElement>; 
  @ViewChild('link') link : ElementRef<HTMLButtonElement>;

  @Input() routerLink;
  @Input() linkHref;

  ripple = false;
  diameter : number = 0;
  left : number = 0;
  top : number = 0;

  buttonClick(event : MouseEvent) {
    this.click.emit(event);
    this.startRipple(event);
  }

  startRipple(event : MouseEvent) {
    this.ripple = false;
    setTimeout(() => {
      const element = this.linkHref ? this.link.nativeElement : this.button.nativeElement;
      this.diameter = Math.max(element.clientWidth, element.clientHeight);
      const radius = this.diameter / 2;
      const offset = this.cumulateOffset(element);
      this.left = event.clientX - (offset.left + 50);
      this.top = event.clientY - (offset.top + 50);
      this.ripple = true;
    });
  }

  cumulateOffset(element : HTMLElement) : {top: number, left : number} {
    let top = 0, left = 0;
    do {
        top += element.offsetTop  || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent as HTMLElement;
    } while(element);

    return {
      top: top,
      left: left
    };
  };
}

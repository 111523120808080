
<div class="connection-view w-100 h-100">

  <div class="title" [innerHTML]="'association.connection.title' | translate"></div>
  <div class="subtitle" [innerHTML]="'association.connection.sub-title' | translate"></div>

  <div class="instruction-list">
    <krd-sticker-list [stickers]="instructionStickers"></krd-sticker-list>
  </div>

  <div class="pairing-section">
    <krd-pairing-card [connectionData]="connectionData"></krd-pairing-card>
  </div>

  <krd-custom-background-passer></krd-custom-background-passer>
</div>
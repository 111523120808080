import {Component, Input} from '@angular/core';

@Component({
  selector: 'krd-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss']
})
export class IndicatorComponent {

  @Input() dataIndicator;
  @Input() data;
  @Input() modeCoaching;
  @Input() currentSpeed: number;
  @Input() displayMainPower: boolean = true;
  @Input() displayMainSpeed: boolean = false;

  public equipmentInclineColor(value: number): string {
    const referenceValues = {
      top: this.dataIndicator.slope * 1.2,
      down: this.dataIndicator.slope * 0.8,
    };

    if(value <= referenceValues.top && value >= referenceValues.down) {
      return 'green-color';
    } else if (value > referenceValues.top) {
      return 'orange-color';
    } else if (value < referenceValues.down) {
      return 'red-color';
    } else {
      return '';
    }
  }

}

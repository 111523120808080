import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ChartModule } from 'angular-highcharts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QRCodeModule } from 'angularx-qrcode';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LoadingComponent } from './components/loading/loading.component';
import { VideoScreenComponent } from './components/video-screen/video-screen.component';
import { WebSocketService } from './services/web-socket.service';
import { BrowserService } from './services/browser.service';
import { TimelineComponent } from './components/timeline/timeline.component';
import { IndicatorComponent } from './components/indicator/indicator.component';
import { PodiumComponent } from './components/podium/podium.component';
import { ToKmPipe } from './pipe/to_km.pipe';
import { ChartComponent } from './components/chart/chart.component';
import { ToMileOrMeterPipe } from './pipe/to_mile_or_meter.pipe';
import { UnitMeterMilePipe } from './pipe/unit_meter_mile.pipe';
import { ConvertSpeedPipe } from './pipe/ConvertSpeed.pipe';
import { ConvertSpeedRowerPipe } from './pipe/ConvertSpeedRower.pipe';
import { UnitSpeedPipe } from './pipe/unit_speed.pipe';
import { GradientFeelComponent } from './components/gradient-feel/gradient-feel.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageService } from './services/language.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewsModule } from './views/views.module';
import { CustomBackgroundService } from './services/custom-background.service';

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    VideoScreenComponent,
    TimelineComponent,
    IndicatorComponent,
    PodiumComponent,
    ToKmPipe,
    ChartComponent,
    ToMileOrMeterPipe,
    UnitMeterMilePipe,
    ConvertSpeedPipe,
    ConvertSpeedRowerPipe,
    UnitSpeedPipe,
    GradientFeelComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    ViewsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en-EN',
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    DeviceDetectorModule.forRoot(),
    QRCodeModule,
    ChartModule,
    AppRoutingModule
  ],
  providers: [
    WebSocketService,
    BrowserService,
    LanguageService,
    CustomBackgroundService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locale/');
}

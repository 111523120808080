export class ConnectionData {
  application_name: string;
  device_id: string;
  lang: string;
  message: string;
  name: string;
  success: boolean;
  type: string;
  units: string;
  user_avatar_url: string;
  username: string;
  version_name: string;
}
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
    selector: 'krd-lang',
    templateUrl: './lang.component.html',
    styleUrls: ['./lang.component.scss']
})
export class LangComponent implements OnInit {

    allAvailableLanguages: any[];
    currentLanguage: any;

    constructor(
        public translate: TranslateService,
        private languageService: LanguageService
    ) {}

    ngOnInit() {
        this.currentLanguage = (this.languageService.get()) ? this.languageService.get() : this.translate.defaultLang;
        this.allAvailableLanguages = this.translate.getLangs().filter(language => {
            if (language !== this.currentLanguage) {
                return language;
            }
        });
    }

    switchLang(lang: string) {
        this.languageService.listenToLangLoading(this.translate.use(lang));
        this.currentLanguage = lang;
        this.languageService.set(lang);
        this.allAvailableLanguages = this.translate.getLangs().filter(language => {
            if (language !== this.currentLanguage) {
                return language;
            }
        });
    }

    getLanguageFirstCharacters(lang : string) : string {
        return lang.split("-")[0];
    }
}

import { BrowserModule } from "@angular/platform-browser";
import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { ChartModule } from "angular-highcharts";
import { QRCodeModule } from "angularx-qrcode";
import {
  HttpClient,
  HttpClientModule,
  provideHttpClient,
} from "@angular/common/http";
import { WebSocketService } from "./services/web-socket.service";
import { BrowserService } from "./services/browser.service";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LanguageService } from "./services/language.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CustomBackgroundService } from "./services/custom-background.service";
import { provideRouter, RouterModule, RouterOutlet } from "@angular/router";
import { routes } from "./app.routes";
import { SafeHtmlPipe } from "./pipe/safe-html.pipe";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { ConvertSpeedPipe } from "./pipe/ConvertSpeed.pipe";
import { ConvertSpeedRowerPipe } from "./pipe/ConvertSpeedRower.pipe";
import { ToKmPipe } from "./pipe/to_km.pipe";
import { ToMileOrMeterPipe } from "./pipe/to_mile_or_meter.pipe";
import { UnitMeterMilePipe } from "./pipe/unit_meter_mile.pipe";
import { UnitSpeedPipe } from "./pipe/unit_speed.pipe";
import { IndicatorComponent } from "./components/indicator/indicator.component";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    WebSocketService,
    BrowserService,
    LanguageService,
    CustomBackgroundService,
    ChartModule,
    BrowserModule,
    HttpClientModule,
    RouterModule,
    NgbModule,
    QRCodeModule,
    RouterOutlet,
    ChartModule,
    provideHttpClient(),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpTranslateLoader,
          deps: [HttpClient],
        },
      })
    ),
  ],
};
export class AppModule {}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "./assets/locale/");
}

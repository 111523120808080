<div class="container indicator px-0 w-100">
  <div class="w-100 d-flex watt-box justify-content-center align-items-center">
    <div class="px-0">
      <!-- display main watt -->
      <div
        id="watt-number"
        class="text-center"
        *ngIf="dataIndicator && displayMainPower"
      >
        {{ dataIndicator.power }}
      </div>
      <!-- display main speed -->
      <div
        id="watt-number"
        class="text-center"
        *ngIf="dataIndicator && displayMainSpeed"
      >
        <div class="text-center">
          <div *ngIf="data.mode === 'CHALLENGE'">
            <div
              [ngClass]="{
                'orange-color': currentSpeed < 0.8,
                'green-color': currentSpeed >= 0.8
              }"
              *ngIf="dataIndicator && data.equipmentType !== 'rower'"
            >
              {{ dataIndicator.speed | convertSpeed }}
            </div>
            <div
              [ngClass]="{
                'orange-color': currentSpeed < 0.8,
                'green-color': currentSpeed >= 0.8
              }"
              *ngIf="
                dataIndicator &&
                dataIndicator.speed &&
                data.equipmentType === 'rower'
              "
            >
              {{ dataIndicator.speed | convertSpeedRower }}
            </div>
          </div>
          <div *ngIf="data.mode !== 'CHALLENGE'">
            <div *ngIf="dataIndicator && data.equipmentType !== 'rower'">
              {{ dataIndicator.speed | convertSpeed }}
            </div>
            <div
              *ngIf="
                dataIndicator &&
                dataIndicator.speed &&
                data.equipmentType === 'rower'
              "
            >
              {{ dataIndicator.speed | convertSpeedRower }}
            </div>
          </div>
        </div>
      </div>
      <div id="watt-number" class="text-center" *ngIf="!dataIndicator">--</div>
    </div>
    <!-- display main watt icons -->
    <div class="text-center px-0" *ngIf="displayMainPower">
      <div class="watt-icon-box">
        <div class="px-0 ml-1">
          <img
            id="img-watt"
            src="./assets/img/Entrainement-watts-white.svg"
            alt="icone watts"
          />
        </div>
      </div>
      <div class="row ml-2">
        <div class="px-0">
          <div id="watt-text">watts</div>
        </div>
      </div>
    </div>
    <!-- display main speed icon -->
    <div class="text-center px-0" *ngIf="displayMainSpeed">
      <div class="watt-icon-box">
        <div class="px-0 text-center">
          <img
            id="img-watt"
            src="./assets/img/Entrainement-km-heure-white.svg"
            alt="icone km par heure"
          />
        </div>
      </div>
      <div class="ml-2">
        <div class="px-0">
          <div
            id="watt-text"
            *ngIf="
              dataIndicator &&
              dataIndicator.speed &&
              data.equipmentType !== 'rower'
            "
          >
            {{ dataIndicator.speed | unitSpeed }}
          </div>
          <div
            id="watt-text"
            *ngIf="
              (!dataIndicator || !dataIndicator.speed) &&
              data.equipmentType !== 'rower'
            "
          >
            km/h
          </div>
          <div id="watt-text" *ngIf="data.equipmentType === 'rower'">/500m</div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-flex w-100 intermediate-box align-items-center justify-content-center ml-2 px-0"
  >
    <!-- display not main speed -->
    <div class="text-center px-0" *ngIf="!displayMainSpeed">
      <div *ngIf="data.mode === 'CHALLENGE'">
        <div
          class="intermediate-number"
          [ngClass]="{
            'orange-color': currentSpeed < 0.8,
            'green-color': currentSpeed >= 0.8
          }"
          *ngIf="dataIndicator && data.equipmentType !== 'rower'"
        >
          {{ dataIndicator.speed | convertSpeed }}
        </div>
        <div
          class="intermediate-number mr-1"
          [ngClass]="{
            'orange-color': currentSpeed < 0.8,
            'green-color': currentSpeed >= 0.8
          }"
          *ngIf="
            dataIndicator &&
            dataIndicator.speed &&
            data.equipmentType === 'rower'
          "
        >
          {{ dataIndicator.speed | convertSpeedRower }}
        </div>
        <div class="intermediate-number" *ngIf="!dataIndicator">--</div>
      </div>
      <div *ngIf="data.mode !== 'CHALLENGE'">
        <div
          class="intermediate-number"
          *ngIf="dataIndicator && data.equipmentType !== 'rower'"
        >
          {{ dataIndicator.speed | convertSpeed }}
        </div>
        <div
          class="intermediate-number mr-1"
          *ngIf="
            dataIndicator &&
            dataIndicator.speed &&
            data.equipmentType === 'rower'
          "
        >
          {{ dataIndicator.speed | convertSpeedRower }}
        </div>
        <div class="intermediate-number" *ngIf="!dataIndicator">--</div>
      </div>
    </div>
    <!-- display not main power -->
    <div class="text-center px-0" *ngIf="!displayMainPower">
      <div class="intermediate-number" *ngIf="!dataIndicator">--</div>
      <div class="intermediate-number" *ngIf="dataIndicator">
        {{ dataIndicator.power }}
      </div>
    </div>
    <!-- display not main speed icon -->
    <div class="px-0" *ngIf="!displayMainSpeed">
      <div>
        <div class="px-0 text-center">
          <img
            id="img-km"
            src="./assets/img/Entrainement-km-heure.svg"
            alt="icone km par heure"
          />
        </div>
      </div>
      <div>
        <div class="px-0 text-center">
          <div
            id="km-text"
            *ngIf="
              dataIndicator &&
              dataIndicator.speed &&
              data.equipmentType !== 'rower'
            "
          >
            {{ dataIndicator.speed | unitSpeed }}
          </div>
          <div
            id="km-text"
            *ngIf="
              (!dataIndicator || !dataIndicator.speed) &&
              data.equipmentType !== 'rower'
            "
          >
            km/h
          </div>
          <div id="km-text" *ngIf="data.equipmentType === 'rower'">/500m</div>
        </div>
      </div>
    </div>
    <!-- display not main power icon -->
    <div class="px-0" *ngIf="!displayMainPower">
      <div>
        <div class="px-0 text-center">
          <img
            id="img-km"
            src="./assets/img/Entrainement-watts.svg"
            alt="icone watt"
          />
        </div>
      </div>
      <div>
        <div class="px-0 text-center">
          <div id="km-text">watts</div>
        </div>
      </div>
    </div>
    <div class="px-0 text-center intermediate-number" *ngIf="dataIndicator">
      {{ dataIndicator.distance | toMileOrMeter }}
    </div>
    <div class="px-0 text-center intermediate-number" *ngIf="!dataIndicator">
      --
    </div>
    <div class="px-0 text-center mr-3">
      <div>
        <div class="px-0 text-center">
          <img
            id="img-km"
            src="./assets/img/Entrainement-distance.svg"
            alt="icone distance"
          />
        </div>
      </div>
      <div>
        <div class="px-0 text-center">
          <div id="km-text" *ngIf="!dataIndicator">km</div>
          <div
            id="km-text"
            *ngIf="dataIndicator && dataIndicator.distance > 1000"
          >
            {{ dataIndicator.distance | unitMeterMile }}
          </div>
          <div
            id="km-text"
            *ngIf="dataIndicator && dataIndicator.distance < 1000"
          >
            {{ dataIndicator.distance | unitMeterMile }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex w-100 justify-content-center lower-box ml-4">
    <div class="col text-center">
      <div class="row">
        <div class="col">
          <img
            id="img-pente"
            src="./assets/img/Entrainement-Pente.svg"
            alt="icone de pente"
            *ngIf="!modeCoaching"
          />
          <img
            id="img-pente"
            src="./assets/img/Entrainement-endurance-gray.svg"
            alt="icone de resistance"
            *ngIf="modeCoaching && data.equipmentType !== 'treadmill'"
          />
          <img
            id="img-pente"
            src="./assets/img/Entrainement-Pente.svg"
            alt="icone de resistance"
            *ngIf="modeCoaching && data.equipmentType === 'treadmill'"
          />
        </div>
      </div>
      <div class="row">
        <div
          class="col lower-indicator-number"
          *ngIf="
            dataIndicator &&
            !modeCoaching &&
            dataIndicator.slope != '--' &&
            (dataIndicator.equipment_incline == '--' ||
              dataIndicator.equipment_incline == -999)
          "
        >
          {{ dataIndicator.slope | number : "1.1-1" }}
        </div>
        <!-- equipment_incline -->
        <div
          class="col lower-indicator-number"
          *ngIf="
            dataIndicator &&
            !modeCoaching &&
            dataIndicator.equipment_incline != '--' &&
            dataIndicator.equipment_incline != -999
          "
          [ngClass]="equipmentInclineColor(dataIndicator.equipment_incline)"
        >
          {{ dataIndicator.equipment_incline | number : "1.1-1" }}
        </div>
        <div
          class="col lower-indicator-number"
          *ngIf="dataIndicator && modeCoaching"
        >
          {{ dataIndicator.resistance_percentage }}
        </div>
        <div
          class="col lower-indicator-number"
          *ngIf="!dataIndicator || dataIndicator.slope == '--'"
        >
          --
        </div>
      </div>
      <div class="row">
        <div class="col grey-text">%</div>
      </div>
    </div>
    <div class="col text-center">
      <div class="row">
        <div class="col">
          <img
            id="img-kcal"
            src="./assets/img/Entrainement-KCAL.svg"
            alt="icone de calorie"
          />
        </div>
      </div>
      <div class="row">
        <div class="col lower-indicator-number" *ngIf="dataIndicator">
          {{ dataIndicator.calories }}
        </div>
        <div class="col lower-indicator-number" *ngIf="!dataIndicator">--</div>
      </div>
      <div class="row">
        <div class="col grey-text">kcal</div>
      </div>
    </div>
    <div class="col text-center">
      <div class="row">
        <div class="col">
          <img
            id="img-bpm"
            src="./assets/img/Entrainement-BPM.svg"
            alt="icone de pulsation"
          />
        </div>
      </div>
      <div class="row">
        <div class="col lower-indicator-number" *ngIf="dataIndicator">
          {{ dataIndicator.heartrate }}
        </div>
        <div class="col lower-indicator-number" *ngIf="!dataIndicator">--</div>
      </div>
      <div class="row">
        <div class="col grey-text">bpm</div>
      </div>
    </div>
    <div class="col text-center">
      <div class="row">
        <div class="col">
          <img
            id="img-rpm"
            src="./assets/img/Entrainement-RPM.svg"
            alt="icone"
          />
        </div>
      </div>
      <div class="row">
        <div class="col lower-indicator-number" *ngIf="dataIndicator">
          {{ dataIndicator.cadence }}
        </div>
        <div class="col lower-indicator-number" *ngIf="!dataIndicator">--</div>
      </div>
      <div class="row">
        <div class="col grey-text" *ngIf="data.equipmentType !== 'rower'">
          rpm
        </div>
        <div class="col grey-text" *ngIf="data.equipmentType === 'rower'">
          spm
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class LanguageService {

    langEmitter = new EventEmitter<string>();
    loadedLangEmitter = new EventEmitter<string>();

    private loadingSubscription : Subscription = null;

    constructor() { }

    set(lang: string): void {
        localStorage.setItem('lang', lang);
        this.langEmitter.emit(lang);
    }

    get(): string {
        return localStorage.getItem('lang');
    }

    format(): string {
        return this.get().split('-')[0];
    }

    listenToLangLoading(observable : Observable<any>) : void {
        if(this.loadingSubscription) this.loadingSubscription.unsubscribe();
        this.loadingSubscription = observable.subscribe(result => {
            this.loadedLangEmitter.emit();
        });
    }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'convertSpeedRower' })
export class ConvertSpeedRowerPipe implements PipeTransform {

    transform(value: number): string {        
        if(value != 0) {
            let timeBy500m = Math.floor((60 / (value * 2)) * 60);            
            const minutes = Math.floor(timeBy500m / 60);
            const seconds = timeBy500m - (minutes * 60);
    
            return (minutes < 10 ? "0" + minutes : minutes.toString()) + ":" + (seconds < 10 ? "0" + seconds : seconds.toString());
        } else {
            return '--';
        }
        
    }
}

import { AfterViewInit, Component, OnDestroy, ViewChild, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { WebSocketService } from '../../services/web-socket.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as polyUtil from 'polyline-encoded';
import * as L from '../../../assets/leaflet/leaflet.js';
import * as $ from '../../../../node_modules/jquery/dist/jquery.min.js';
import { BrowserService } from 'src/app/services/browser.service';
import { environment } from '../../../environments/environment';
import moment from 'moment';

declare var jwplayer: any;
@Component({
    selector: 'krd-video-screen',
    templateUrl: './video-screen.component.html',
    styleUrls: ['./video-screen.component.scss'],
})
export class VideoScreenComponent implements OnInit, AfterViewInit, OnDestroy {

    videoData: any = null;
    loading = true;

    time = {
        hour: '00',
        minute: '00',
        second: '00'
    };

    data: any;
    elem: any;
    ratio: number;
    blackColumn = false;
    appColor: string;
    sections: any;
    coachingIntervals: any;
    map: any;
    subscription: Subscription;
    subscriptionEventCommand: Subscription;
    videoOption: any;
    distance: number;
    position: number;
    markers = [];
    userDistance: number;
    allDistance = [];
    distanceFromOther = [];
    rankEnable = true;
    mapEnable = true;
    chartEnable = true;
    modeCoaching = false;
    mapZoom = true;
    displayedPolyline: any;
    timeSecond = 0;
    muted = true;
    showPlay = false;
    readyToResume = false;
    url: string;
    currentTime = 0;
    // buffering variable
    checkInterval = 200.0;
    lastPlayPos = 0;
    currentPlayPos = 0;
    bufferingDetected = false;
    dataTime = 0;
    podiumHeight = 0;
    currentSpeed = 1;
    playerLength = 0;
    displayedGo = false;
    blinkGo = true;
    displayedLastUnitDistance = false;
    blinkLastUnitDistance = true;
    units = 'metric';
    lastUpdateTime = moment();
    jwplayerInstance: any;
    displayMainPower: boolean = true;
    displayMainSpeed: boolean = false;

    constructor(
        private socket: WebSocketService,
        private router: Router,
        private browserService: BrowserService
    ) {
        // this.sceneReadyInit();
        this.videoData = this.socket.videoData;
        this.prepareData();
        // this.loadFont();
    }

    public prepareData() {
        if (!environment.production) {
            // console.log('new Video', this.videoData);
        }
        // switch color
        if (this.videoData && this.videoData.secondary_color) {
            this.appColor = '#' + this.videoData.secondary_color.substring(3, this.videoData.secondary_color.length);
        }
        // sections
        if (this.videoData && this.videoData.sections) {
            this.sections = this.videoData.sections;

        }
        if (this.videoData && this.videoData.coaching_intervals) {
            this.coachingIntervals = this.videoData.coaching_intervals;
        }
        if (this.videoData) {
            // resume training
            if (this.videoData.video_resume_time) {
                this.currentTime = this.videoData.video_resume_time;
            }
            this.url = this.videoData.url + '#t=' + this.currentTime;
            this.videoOption = {
                mode: this.videoData.training_mode,
                duration: this.videoData.video_duration,
                length: this.videoData.video_length,
                isInteractif: this.videoData.is_equipment_interactive,
                equipmentType: this.videoData.equipment_type
            };
            if (this.videoData.training_mode === 'COACHING') {
                this.modeCoaching = true;
            } else {
                this.modeCoaching = false;
            }
        }
    }

    public preparePolyline(map) {
        if (this.videoData && this.videoData.polyline) {
            const polyline = this.decodePolyline(this.videoData.polyline);

            if (polyline.length) {
                this.displayedPolyline = new L.Polyline(polyline, {
                    color: 'red',
                    weight: 5,
                    opacity: 0.5,
                    smoothFactor: 1
                });
                this.displayedPolyline.addTo(map);
                map.fitBounds(this.displayedPolyline.getBounds());
            } else {
                if (!environment.production) {
                    console.log('error decoding polyline', this.videoData.polyline);
                }
            }
        }
    }

    ngOnInit() {
        this.map = L.map('map', { zoomControl: false });
        $('.leaflet-control-attribution').hide();
        L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            opacity: 0.8,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        }).addTo(this.map);
        this.preparePolyline(this.map);
        jwplayer.key = 'QQvw0G5d6xjep9+7t0kwKzJmIzaf6pesEe0nsVDnzRI=';
        this.setBodyZoom(100);
    }

    private setBodyZoom(percents: number): void {
        document.getElementsByTagName('body')[0].style.setProperty('--zoom', (percents / 100).toString());
    }

    private podiumSize() {
        let offset = 171;
        if (this.modeCoaching) {
            offset = 189;
        }
        if (!this.chartEnable) {
            offset = 0;
        }
        const elmnt = document.getElementById('indicator');
        let indicatorHeight = 0;
        if (elmnt) {
            indicatorHeight = elmnt.offsetHeight;
        }
        if (elmnt && indicatorHeight !== 0) {
            this.podiumHeight = window.innerHeight - indicatorHeight - offset;
        }
    }

    @HostListener('window:resize')
    onWindowResize() {
        // debounce resize, wait for resize to finish before doing stuff
        this.podiumSize();
    }

    ngAfterViewInit() {
        if (!this.videoData) {
            this.router.navigate(['/association']);
            return;
        }

        this.jwplayerInstance = jwplayer('video').setup({
            file: this.url,
            height: '100%',
            width: '100%',
            autostart: false,
            streching: 'uniform',
            skin: {
                timeslider: {
                    progress: '#FDCC24',
                },
                controlbar: {
                    iconsActive: '#FDCC24'
                }
            },
            preload: 'auto',
            controls: false,
            displaydescription: false,
            displaytitle: false,
            mute: true,
        });

        // jwplayer events
        this.jwplayerInstance.on('playlist', () => {
            this.loadedData();
            this.getRatio();
        });

        this.jwplayerInstance.on('complete', () => {
            this.videoEnded();
        });

        this.jwplayerInstance.on('time', () => {
            this.onTimeUpdate(this.jwplayerInstance.getPosition() * 1000, this.jwplayerInstance.getPlaybackRate());
        });
        this.jwplayerInstance.on('click', () => {
            this.toggleFullScreen();
        });

        const units = localStorage.getItem('units');
        if (units) {
            this.units = units;
        }
        this.elem = document.documentElement;

        // pre load video
        // this.loadVideo(this.videoData.url)
        // .then(blobUrl => { // now it's loaded
        //   document.body.className = 'loaded';
        //   let vid = document.querySelector('video');
        //   vid.src = blobUrl.toString(); // we just set our mediaElement's src to this blobURL
        //   vid.onload = () => URL.revokeObjectURL(blobUrl.toString());
        // }).catch((err) => console.log(err));

        // this.setRenderer();
        this.initSocketListeners();

        this.subscription = this.socket.event.subscribe(data => {
            this.podiumSize();

            if (data.players) {
                // position of local player
                let i = 0;
                this.allDistance = [];
                this.playerLength = 0;
                for (const player of data.players) {
                    if (player.type !== 'CONTRIBUTOR') {
                        this.playerLength++;
                    }
                    i++;
                    this.allDistance.push(player.distance);
                    if (player.type === 'LOCAL') {
                        this.userDistance = player.distance;
                        this.position = i;
                        // move map
                        if (player.lat && player.lng && player.lat !== 'null' && player.lng !== 'null') {
                            if (this.mapZoom) {
                                const newLatLng = new L.LatLng(player.lat, player.lng);
                                this.map.setView(newLatLng, 16, { animate: true, easeLinearity: 1, duration: 1 });
                            } else {
                                this.map.fitBounds(this.displayedPolyline.getBounds());
                            }
                        }
                    }
                    // create markers on map
                    if (player.lat && player.lng && player.lat !== 'null' && player.lng !== 'null') {
                        if (!this.markers[player.username + '_' + player.type]) {
                            let leafIcon;
                            if (player.type === 'LOCAL') {
                                leafIcon = L.Icon.extend({
                                    options: {
                                        iconSize: [60, 60],
                                        iconAnchor: [30, 30],
                                        className: 'map-marker'
                                    }
                                });
                            } else {
                                if (player.type !== 'CONTRIBUTOR') {
                                    leafIcon = L.Icon.extend({
                                        options: {
                                            iconSize: [50, 50],
                                            iconAnchor: [25, 25],
                                            className: 'map-marker'
                                        }
                                    });
                                }
                            }
                            if (player.type !== 'CONTRIBUTOR') {
                                const icon = new leafIcon({
                                    iconUrl: player.avatar_url
                                });
                                const newLatLng = new L.LatLng(player.lat, player.lng);
                                const marker = new L.Marker(newLatLng, { icon: icon });
                                this.markers[player.username + '_' + player.type] = marker;
                                marker.addTo(this.map);
                                if (player.type === 'LOCAL') {
                                    marker._icon.style.backgroundColor = this.appColor;
                                }
                            }
                        }
                        if (player.type !== 'CONTRIBUTOR') {
                            // move markers
                            const newLatLng = new L.LatLng(player.lat, player.lng);
                            this.markers[player.username + '_' + player.type].setLatLng(newLatLng);
                            this.markers[player.username + '_' + player.type].setZIndexOffset(player.distance);
                        }
                    }
                }

                // calculate distance from other
                this.distanceFromOther = [];
                for (const distance of this.allDistance) {
                    this.distanceFromOther.push(distance - this.userDistance);
                }
            }
            this.distance = data.distance;
            this.dataTime = data.time;
            this.time = this.convertSecondToTime(data.time);

            data.speed = (Math.round(data.speed * 10) / 10).toFixed(1);
            // mobile app send -1 when the data is unknown
            for (const key of Object.keys(data)) {
                if (data[key] < 0 && key !== 'time' && key !== 'slope') {
                    data[key] = '--';
                }
            }
            this.data = data;

        });
        this.subscriptionEventCommand = this.socket.eventCommand.subscribe(data => {
            switch (data.command) {
                case 'RANK:DISABLE':
                    this.rankEnable = false;
                    break;
                case 'RANK:GLOBAL':
                case 'RANK:ZOOM':
                    this.rankEnable = true;
                    break;
                case 'MAP:DISABLE':
                    this.mapEnable = false;
                    break;
                case 'MAP:GLOBAL':
                    this.mapZoom = false;
                    this.mapEnable = true;
                    setTimeout(() => {
                        this.map.invalidateSize();
                    }, 500);
                    break;
                case 'MAP:ZOOM':
                    this.mapZoom = true;
                    this.mapEnable = true;
                    setTimeout(() => {
                        this.map.invalidateSize();
                    }, 500);
                    break;
                case 'CHART:DISABLE':
                    this.chartEnable = false;
                    break;
                case 'CHART:GLOBAL':
                case 'CHART:ZOOM':
                    this.chartEnable = true;
                    break;
                case 'MUTE:TRUE':
                    if (this.jwplayerInstance) {
                        this.jwplayerInstance.setMute(true);
                    }
                    this.muted = true;
                    break;
                case 'MUTE:FALSE':
                    if (this.jwplayerInstance) {
                        this.jwplayerInstance.setMute(false);
                    }
                    this.muted = false;
                    break;
                case 'MAIN_VALUE:POWER':
                    this.displayMainPower = true;
                    this.displayMainSpeed = false;
                    break;
                case 'MAIN_VALUE:SPEED':
                    this.displayMainPower = false;
                    this.displayMainSpeed = true;
                    break;
            }
        });
        this.socket.isLG.subscribe(ev => {
            console.log('hi by the subjet, value: ', ev)
            if(ev == true) {
                
                setTimeout(() => {
                    this.showPlay = false;
                    this.jwplayerInstance.setMute(true);
    
                    this.readyToResume = false;
                    
                    this.socket.socket.emit('video_resume', {
                        id: this.videoData.id,
                    });
                    
                    document.getElementById('playContainer').style.visibility = 'hidden';
                }, 2000);
            }
        })
    }

    private decodePolyline(polyline: string): number[] {
        return polyUtil.decode(polyline);
    }

    private convertSecondToTime(time: number): any {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time - (hours * 3600)) / 60);
        const seconds = time - (hours * 3600) - (minutes * 60);

        return {
            hour: hours < 10 ? '0' + hours : hours.toString(),
            minute: minutes < 10 ? '0' + minutes : minutes.toString(),
            second: seconds < 10 ? '0' + seconds : seconds.toString()
        };
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.subscriptionEventCommand) {
            this.subscriptionEventCommand.unsubscribe();
        }
        if (this.jwplayerInstance) {
            this.jwplayerInstance.pause();
        }
        if (this.socket.socket) {
            ['video_play', 'video_pause', 'video_speed'].forEach(channel => {
                this.socket.socket.off(channel);
            });

        }
    }


    public initSocketListeners() {
        this.listenPlayVideo();
        this.listenPauseVideo();
        this.listenChangeSpeed();
    }

    /**
     * listen pause video
     */
    public listenPauseVideo() {
        this.socket.socket.on('video_pause', (dataVideo, ack) => {
            if (!environment.production) {
                console.log('pause received');
            }
            this.jwplayerInstance.pause();
            ack({ id: this.videoData.id, success: true });
        });
    }

    /**
     * listen video playback rate change
     */
    public listenChangeSpeed() {
        this.socket.socket.on('video_speed', (speed, ack) => {
            this.currentSpeed = speed.speed;
            this.changeSpeed(speed.speed);
            if (!environment.production) {
                console.log(speed);
            }

            ack({
                id: this.videoData.id,
                success: true,
                speed: this.jwplayerInstance.getPlaybackRate()
            });
        });
    }

    /**
     * setting playback rate of video
     * @param event
     */
    public changeSpeed(event) {
        if (this.jwplayerInstance) {
            this.jwplayerInstance.setPlaybackRate(event < 0.0625 ? 0.0625 : event);
        }
    }

    /**
     * toggle full screen mode
     */
    public toggleFullScreen() {
        if (document['fullscreenElement']) {
            this.closeFullScreen();
        } else {
            this.openFullScreen();
        }
    }

    /**
     * video data loaded
     */
    public loadedData() {
        this.readyToResume = false;
        this.loading = false;
        this.videoLoaded();
        this.jwplayerInstance.pause();
        this.map.invalidateSize();
        this.readyToResume = true;
    }

    public getRatio() {
        const width = this.jwplayerInstance.getWidth();
        const height = this.jwplayerInstance.getHeight();
        this.ratio = Math.round((width / height) * 100) / 100;
    }

    public toogleStretch() {
        if (this.ratio && this.ratio === 1.33) {
            // if (this.blackColumn) {
            //     this.videoCmp.nativeElement.style.width = '100%';
            //     this.videoCmp.nativeElement.style.margin = 'none';
            // } else {
            //     this.videoCmp.nativeElement.style.width = '83vw';
            //     this.videoCmp.nativeElement.style.margin = 'auto';
            // }
            this.blackColumn = !this.blackColumn;
        }
    }

    /**
     * close full screen mode
     */
    public closeFullScreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document['mozCancelFullScreen']) {
            document['mozCancelFullScreen']();
        } else if (document['webkitExitFullscreen']) {
            document['webkitExitFullscreen']();
        } else if (document['msExitFullscreen']) {
            document['msExitFullscreen']();
        }
    }

    /**
     * open full screen mode
     */
    public openFullScreen() {
        if (this.elem.requestFullscreen) {
            this.elem.requestFullscreen();
        } else if (this.elem.mozRequestFullScreen) {
            /* Firefox */
            this.elem.mozRequestFullScreen();
        } else if (this.elem.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            this.elem.webkitRequestFullscreen();
        } else if (this.elem.msRequestFullscreen) {
            /* IE/Edge */
            this.elem.msRequestFullscreen();
        }
    }

    /**
     * time update
     * socket emit
     * @param eventTime
     * @param eventSpeed
     */
    public onTimeUpdate(eventTime, eventSpeed) {
        this.timeSecond = Math.round(this.jwplayerInstance.getPosition());
        const now = moment();
        const diff = now.diff(this.lastUpdateTime, 'seconds');

        if (diff >= 1) {
            if (!environment.production) {
                console.log(`EmitTimeChange %c${diff}sec`, 'background: #222; color: #bada55');
            }
            this.lastUpdateTime = now;
            this.socket.emitTimeChange(Math.round(eventTime), eventSpeed);
        }

        // to display the GO element in challenge or multiplayer
        if (
            (this.videoOption.mode === 'CHALLENGE' || this.videoOption.mode === 'MULTIPLAYER')
            && this.timeSecond > 0
            && !this.displayedGo) {
            this.showGo();
        }
        // to display the Last distance element in challenge or multiplayer
        if (
            (this.videoOption.mode === 'CHALLENGE' || this.videoOption.mode === 'MULTIPLAYER')
            && this.timeSecond > 0
            && !this.displayedLastUnitDistance
        ) {
            // last km condition
            if (this.units === 'metric' && this.videoOption.length >= 2000 && this.videoOption.length < (this.distance + 1000)) {
                this.showLastUnitDistance();
            }
            // last mile condition
            if (this.units !== 'metric' && this.videoOption.length >= 3218 && this.videoOption.length < (this.distance + 1600)) {
                this.showLastUnitDistance();
            }
        }
    }

    /**
     * video loaded
     * socket emit
     */
    public videoLoaded() {
        // sound available in video
        if (this.videoData.soundAvailable) {
            this.jwplayerInstance.setMute(false);
            if (!this.browserService.isFocused) {
                this.socket.socket.emit('video_loaded', {
                    success: false,
                    id: this.videoData.id,
                });
                this.showPlay = true;
                setTimeout(() => {
                    document.getElementById('play').focus();
                }, 100);
                const playBtn = document.getElementById('play');
                playBtn ? playBtn.focus() : null;
            } else {
                try {
                    document.getElementById('video-con').click();
                    const playpromise = this.jwplayerInstance.play();
                    if (playpromise !== null) {
                        playpromise.then(() => {
                            if (!environment.production) {
                                console.log('play ready ? success');
                            }

                            this.socket.socket.emit('video_loaded', {
                                success: true,
                                id: this.videoData.id,
                            });
                        });
                        playpromise.catch(() => {
                            if (!environment.production) {
                                console.log('play ready ? false');
                            }
                            this.socket.socket.emit('video_loaded', {
                                success: false,
                                id: this.videoData.id,
                            });
                            this.showPlay = true;
                            setTimeout(() => {
                                document.getElementById('play').focus();
                            }, 100);
                        });
                    } else {
                        if (!environment.production) {
                            console.log('play ready ? success');
                        }
                        this.socket.socket.emit('video_loaded', {
                            success: true,
                            id: this.videoData.id,
                        });
                    }
                } catch {
                    if (!environment.production) {
                        console.log('play ready ? false');
                    }
                    this.socket.socket.emit('video_loaded', {
                        success: false,
                        id: this.videoData.id,
                    });
                    this.showPlay = true;
                    setTimeout(() => {
                        document.getElementById('play').focus();
                    }, 100);
                    const playBtn = document.getElementById('play');
                    playBtn ? playBtn.focus() : null;
                }
            }
        } else {
            // video is without sound we check the focus of the browser's tab
            if (!this.browserService.isFocused) {
                this.socket.socket.emit('video_loaded', {
                    success: false,
                    id: this.videoData.id,
                });
                this.showPlay = true;
                setTimeout(() => {
                    document.getElementById('play').focus();
                }, 100);
                const playBtn = document.getElementById('play');
                playBtn ? playBtn.focus() : null;
            } else {
                this.socket.socket.emit('video_loaded', {
                    success: true,
                    id: this.videoData.id,
                });
            }
        }

        if (!environment.production) {
            console.log('video loaded ...');
        }
        setInterval(this.checkBuffering.bind(this), this.checkInterval);
    }

    public checkBuffering() {
        this.currentPlayPos = this.jwplayerInstance.getPosition();

        // checking offset should be at most the check interval
        // but allow for some margin
        // var offset = (this.checkInterval - (this.checkInterval * 50 / 100)) / 1000;

        // if no buffering is currently detected,
        // and the position does not seem to increase
        // and the player isn't manually paused...
        if (
            !this.bufferingDetected
            && this.currentPlayPos === this.lastPlayPos
            && this.jwplayerInstance.getState() !== 'paused' && this.currentPlayPos > 1
        ) {
            this.bufferingDetected = true;
        }

        // if we were buffering but the player has advanced,
        // then there is no buffering
        if (
            this.bufferingDetected
            && this.currentPlayPos > this.lastPlayPos
            && this.jwplayerInstance.getState() !== 'paused'
        ) {
            this.bufferingDetected = false;
        }
        this.lastPlayPos = this.currentPlayPos;
    }

    public showGo() {
        this.displayedGo = true;
        this.blinkGo = false;
        setTimeout(() => {
            this.blinkGo = true;
        }, 3200);
    }

    public showLastUnitDistance() {
        this.displayedLastUnitDistance = true;
        this.blinkLastUnitDistance = true;
        // setTimeout(() => {
        //     this.blinkLastUnitDistance = true;
        // }, 3200);
    }


    /**
     * video play listener
     */
    public listenPlayVideo() {
        this.socket.socket.on('video_play', (dataVideo, ack) => {
            this.jwplayerInstance.play();
            // try {
            //   const playpromise = this.videoCmp.nativeElement.play();
            //   if (playpromise !== null) {
            //     playpromise.then(() => {
            //       ack({ success: true, id: dataVideo.id, speed: dataVideo.speed });
            //     });
            //     playpromise.catch(() => {
            //       ack({ success: false, error: 'Please interact with browser first' });
            //       this.showPlay = true;
            //     });
            //   } else {
            //     ack({ success: true, id: dataVideo.id, speed: dataVideo.speed });
            //   }
            // } catch {
            //   ack({ success: false, error: 'Please interact with browser first' });
            //   this.showPlay = true;
            // }
        });
    }

    /**
     * video end event
     * socket emit
     */
    public videoEnded() {
        if (!environment.production) {
            console.log('video ended ...');
        }
        this.socket.emitVideoEnd();
    }

    public videoResume() {
        if (this.muted) {
            this.jwplayerInstance.setMute(true);
        } else {
            this.jwplayerInstance.setMute(false);
        }
        if (!environment.production) {
            console.log('ready to resume ?', this.readyToResume);
        }
        if (this.readyToResume) {
            this.readyToResume = false;
            if (!environment.production) {
                console.log('video resume ...');
            }
            this.socket.socket.emit('video_resume', {
                id: this.videoData.id,
            });
        }

        this.showPlay = false;

    }

    public loadVideo(url) {
        return new Promise((resolve, reject) => { // here we download it entirely
            const request = new XMLHttpRequest();
            request.responseType = 'blob';
            request.onload = (evt) => resolve(request.response);
            request.onerror = reject;
            request.open('GET', url);
            request.send();
        }).then((blob: any) =>
            new Promise((resolve, reject) => {
                resolve(URL.createObjectURL(blob)); // return the blobURL directly
            })
        );

    }

    public convertSecondtoMinuteSecond(time: number): any {
        time = Math.abs(time);
        const minutes = Math.floor(time / 60);
        const seconds = time - (minutes * 60);
        return (minutes < 10 ? '0' + minutes : minutes.toString()) + ':' + (seconds < 10 ? '0' + seconds : seconds.toString());
    }

}

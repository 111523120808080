import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { WebSocketService } from "src/app/services/web-socket.service";
import { Subscription } from "rxjs";
import { ToMileOrMeterPipe } from "src/app/pipe/to_mile_or_meter.pipe";
import { UnitMeterMilePipe } from "src/app/pipe/unit_meter_mile.pipe";

@Component({
  selector: "krd-timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.scss"],
  imports: [ToMileOrMeterPipe, UnitMeterMilePipe],
  standalone: true,
})
export class TimelineComponent implements OnInit, OnDestroy {
  @Input() time: any;
  @Input() timeSecond: number;
  @Input() appColor = "rgb(3, 144, 182)";
  @Input() data: any;
  @Input() distance: any;
  @Input() currentSpeed: number;
  lengthLast: number;
  subscription: Subscription;
  videoSpeedSub: Subscription;
  percent = 0;
  durationLast: any = {
    hour: "00",
    minute: "00",
    second: "00",
  };

  constructor(private socket: WebSocketService) {}

  ngOnInit() {
    this.subscription = this.socket.event.subscribe((res) => {
      if (this.data && this.data.mode) {
        if (this.data.mode === "CHALLENGE") {
          this.lengthLast = this.data.length - this.distance;
          this.percent = (this.distance / this.data.length) * 100;
        } else if (this.data.mode === "DISCOVERY") {
          let time = this.data.duration - this.timeSecond;
          this.percent = (this.timeSecond / this.data.duration) * 100;
          if (time < 0) {
            time = 0;
          }
          if (this.percent > 100) {
            this.percent = 100;
          }
          this.durationLast = this.convertSecondToTime(time);
        } else {
          let time = this.data.duration - this.timeSecond;
          this.percent = (this.timeSecond / this.data.duration) * 100;
          if (time < 0) {
            time = 0;
          }
          if (this.percent > 100) {
            this.percent = 100;
          }
          this.durationLast = this.convertSecondToTime(time);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private convertSecondToTime(time: number): any {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - hours * 3600) / 60);
    const seconds = time - hours * 3600 - minutes * 60;

    return {
      hour: hours < 10 ? "0" + hours : hours.toString(),
      minute: minutes < 10 ? "0" + minutes : minutes.toString(),
      second: seconds < 10 ? "0" + seconds : seconds.toString(),
    };
  }
}

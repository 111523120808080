import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "unitSpeed", standalone: true })
export class UnitSpeedPipe implements PipeTransform {
  transform(value: number): string {
    const units = localStorage.getItem("units");

    switch (units) {
      case "imperial":
        return "mph";
      case "metric":
      default:
        return "km/h";
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssociationHomeComponent } from '../association-home/association-home.component';
import { AssociationConnectedComponent } from '../association-connected/association-connected.component';
import { AssociationComponent } from './association.component';

const routes: Routes = [
  {
    path: 'association',
    component: AssociationComponent,
    children: [
      { 
        path: '', 
        component: AssociationHomeComponent,
      },
      { 
        path: 'connected', 
        component: AssociationConnectedComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AssociationRoutingModule {
}

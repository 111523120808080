<div class="container">
  <div class="d-flex justify-content-center">
    <div class="progress-bar-container p-2">
      <div class="d-flex m-0 align-items-center">
        <div class="col px-0" *ngIf="data.mode === 'CHALLENGE'">
          <img
            class="orange-color"
            src="assets/img/eject-24px.svg"
            height="18"
            width="18"
            *ngIf="currentSpeed < 0.8"
          />
          <img
            class="green-color"
            src="assets/img/play_arrow-24px.svg"
            height="18"
            width="18"
            *ngIf="currentSpeed >= 0.8 && currentSpeed <= 1.2"
          />
          <img
            class="green-color"
            src="assets/img/fast_forward-black-18dp.svg"
            height="18"
            width="18"
            *ngIf="currentSpeed > 1.2"
          />
        </div>
        <div class="col">
          <div class="progress ml-2 pr-0">
            <div
              class="progress-bar"
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
              [style.width]="percent + '%'"
            ></div>
          </div>
        </div>
        <div
          class="col pl-0"
          *ngIf="
            data.mode === 'DISCOVERY' || data.mode === 'COACHING';
            else otherMode
          "
          style="white-space: nowrap"
        >
          <span
            *ngIf="
              (durationLast && durationLast.hour !== '00') ||
                durationLast.minute !== '00';
              else onlySecond
            "
          >
            <strong>{{ durationLast.hour }}</strong
            >h<strong>{{ durationLast.minute }}</strong>
          </span>
          <ng-template #onlySecond>
            <span *ngIf="durationLast && durationLast.second !== '00'">
              <strong>{{ durationLast.second }}</strong
              >s
            </span>
            <span *ngIf="durationLast && durationLast.second === '00'">
              <strong>--</strong>s
            </span>
          </ng-template>
        </div>
        <ng-template #otherMode>
          <div class="col pl-0" style="white-space: nowrap" *ngIf="lengthLast">
            <strong>{{ lengthLast | toMileOrMeter }}</strong>
            {{ lengthLast | unitMeterMile }}
          </div>
          <div class="col pl-0" style="white-space: nowrap" *ngIf="!lengthLast">
            <strong>--</strong> m
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div class="time-elapsed-container p-2">
      <div class="d-flex flex-wrap align-items-center justify-content-center">
        <div class="text-center">
          <img
            class="img-timer"
            src="./assets/img/Entrainement-timer.svg"
            alt="Entrainement timer"
          />
        </div>
        <div class="px-0 mr-2">
          <span class="big-number text-center">{{ time.hour }}</span>
          <span class="letter">h</span>
          <span class="big-number text-center">{{ time.minute }}</span>
          <span class="letter">m</span>
          <span class="second text-center">{{ time.second }}s</span>
        </div>
      </div>
    </div>
  </div>
</div>

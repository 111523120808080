import { CommonModule, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { ConvertSpeedPipe } from "src/app/pipe/ConvertSpeed.pipe";
import { ConvertSpeedRowerPipe } from "src/app/pipe/ConvertSpeedRower.pipe";
import { ToMileOrMeterPipe } from "src/app/pipe/to_mile_or_meter.pipe";
import { UnitMeterMilePipe } from "src/app/pipe/unit_meter_mile.pipe";
import { UnitSpeedPipe } from "src/app/pipe/unit_speed.pipe";

@Component({
  selector: "krd-indicator",
  templateUrl: "./indicator.component.html",
  styleUrls: ["./indicator.component.scss"],
  imports: [
    CommonModule,
    NgIf,
    ConvertSpeedPipe,
    ConvertSpeedRowerPipe,
    ToMileOrMeterPipe,
    UnitMeterMilePipe,
    UnitSpeedPipe,
  ],
  standalone: true,
})
export class IndicatorComponent {
  @Input() dataIndicator;
  @Input() data;
  @Input() modeCoaching;
  @Input() currentSpeed: number;
  @Input() displayMainPower: boolean = true;
  @Input() displayMainSpeed: boolean = false;

  public equipmentInclineColor(value: number): string {
    const referenceValues = {
      top: this.dataIndicator.slope * 1.2,
      down: this.dataIndicator.slope * 0.8,
    };

    if (value <= referenceValues.top && value >= referenceValues.down) {
      return "green-color";
    } else if (value > referenceValues.top) {
      return "orange-color";
    } else if (value < referenceValues.down) {
      return "red-color";
    } else {
      return "";
    }
  }
}

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'krd-association',
  templateUrl: './association.component.html',
  styleUrls: ['./association.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AssociationComponent implements OnInit, OnDestroy {
  resizeHandler : any;

  ngOnInit() : void {
    this.resizeHandler = () => {
      this.updateZoom();
    };
    window.addEventListener("resize", this.resizeHandler);
    this.updateZoom();
  }

  ngOnDestroy() : void {
    window.removeEventListener('resize', this.resizeHandler);
  }

  private updateZoom() : void {
    if(window.innerWidth <= 1280 || window.innerHeight <= 720) {
      this.setBodyZoom(67);
    }
    else {
      this.setBodyZoom(100);
    }
  }

  private setBodyZoom(percents : number) : void {
    document.getElementsByTagName('body')[0].style.setProperty("--zoom", (percents / 100).toString());
  }
}

import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Sticker } from 'src/app/models/sticker';

@Component({
  selector: 'krd-instruction-sticker',
  templateUrl: './instruction-sticker.component.html',
  styleUrls: ['./instruction-sticker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InstructionStickerComponent {
  @Input() sticker : Sticker;
  @Input() isLast : boolean;
}

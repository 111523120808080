import { NgForOf } from "@angular/common";
import { Directive, Host, Input } from "@angular/core";

@Directive({
  selector: '[ngForTrackByKey]',
})
export class NgForTrackByKeyDirective<T extends {[key: string]: any}> {
  @Input() ngForTrackByKey = 'id';
  
  constructor(@Host() private ngFor: NgForOf<T>) {
    this.ngFor.ngForTrackBy = (index: number, item: T) => {
      return item[this.ngForTrackByKey];
    };
  }
}
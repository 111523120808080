import { Injectable } from '@angular/core';

@Injectable()
export class BrowserService {

    isFocused: boolean = false;

    setFocused() {
        this.isFocused = true;
    }

    setBlur() {
        this.isFocused = false;
    }

}


<div class="sticker-list">
  <krd-instruction-sticker
    *ngFor="let sticker of stickers; let isLast = last; trackByKey:'id'"
    [sticker]="sticker"
    class="item"
    [isLast]="isLast"
  >
  </krd-instruction-sticker>
</div>

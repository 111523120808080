import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ChartModule } from "angular-highcharts";
import { QRCodeModule } from "angularx-qrcode";
import { DeviceDetectorModule } from "ngx-device-detector";
import { AssociationModule } from "./association/association.module";

const components = []
@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    TranslateModule,
    DeviceDetectorModule.forRoot(),
    QRCodeModule,
    ChartModule,
    AssociationModule
  ],
  exports: [...components]
})
export class ViewsModule {
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'unitMeterMile' })
export class UnitMeterMilePipe implements PipeTransform {
  transform(value: number): string {
    const units = localStorage.getItem('units');

    if(units === 'imperial') {
      if (value === 0) {
        return 'ft';
      }
      let unit = 'ft';

      value = value * 3.28084;

      if (value > 2640) {
        unit = 'mi';
      }
      return unit;
    }
    else { // 'metric'
      if (value === 0) {
        return 'm';
      }
      let unit = 'm';

      if ((value > 1000 && value <= 1999) || (value < -1000 && value >= -1999)) {
        unit = 'km';
      } else if (value >= 2000 || value <= -2000) {
        unit = 'kms';
      }

      return unit;
    }           
  }
}

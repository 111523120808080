import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CustomBackgroundService } from 'src/app/services/custom-background.service';

@Component({
  selector: 'krd-custom-background-passer',
  templateUrl: './custom-background-passer.component.html',
  styleUrls: ['./custom-background-passer.component.scss']
})
export class CustomBackgroundPasserComponent implements AfterViewInit {
  @ViewChild('template') templateRef: TemplateRef<any>;

  constructor(public customBackgroundService : CustomBackgroundService) { }
  
  ngAfterViewInit() {
    this.customBackgroundService.setTemplate(this.templateRef);
  }
}

import { Component, HostListener } from "@angular/core";
import { BrowserService } from "./services/browser.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { LanguageService } from "./services/language.service";
import { RouterOutlet } from "@angular/router";

@Component({
  selector: "app-root",
  template: "<router-outlet></router-outlet>",
  standalone: true,
  imports: [RouterOutlet, TranslateModule],
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(
    translate: TranslateService,
    private browserService: BrowserService,
    private languageService: LanguageService
  ) {
    translate.addLangs(["en-EN", "fr-FR", "de-DE"]);
    const observable = translate.use(
      this.languageService.get() ? this.languageService.get() : "en-EN"
    );
    this.languageService.listenToLangLoading(observable);
  }

  title = "kinomap-remote-display";
  @HostListener("window:focus", ["$event"])
  onFocus(event: FocusEvent): void {
    this.browserService.setFocused();
  }

  @HostListener("window:blur", ["$event"])
  onBlur(event: FocusEvent): void {
    this.browserService.setBlur();
  }
}

import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ChartModule } from "angular-highcharts";
import { QRCodeModule } from "angularx-qrcode";
import { DeviceDetectorModule } from "ngx-device-detector";
import { AppModule } from "src/app/app.module";
import { CustomBackgroundPasserComponent } from "src/app/components/custom-background-passer/custom-background-passer.component";
import { CustomBackgroundComponent } from "src/app/components/custom-background/custom-background.component";
import { InstructionStickerComponent } from "src/app/components/instruction-sticker/instruction-sticker.component";
import { LangComponent } from "src/app/components/lang/lang.component";
import { PairingCardComponent } from "src/app/components/pairing-card/pairing-card.component";
import { RippleButtonComponent } from "src/app/components/ripple-button/ripple-button.component";
import { StickerListComponent } from "src/app/components/sticker-list/sticker-list.component";
import { NgForTrackByKeyDirective } from "src/app/directives/track-by-key.directive";
import { SafeHtmlPipe } from "src/app/pipe/safe-html.pipe";
import { AssociationConnectedComponent } from "../association-connected/association-connected.component";
import { AssociationHomeComponent } from "../association-home/association-home.component";
import { AssociationRoutingModule } from "./association-routing.module";
import { AssociationComponent } from "./association.component";

const components = [
  AssociationComponent,
  AssociationHomeComponent,
  AssociationConnectedComponent,
  RippleButtonComponent,
  StickerListComponent,
  InstructionStickerComponent,
  SafeHtmlPipe,
  CustomBackgroundComponent,
  CustomBackgroundPasserComponent,
  PairingCardComponent,
  NgForTrackByKeyDirective,
  LangComponent
]

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    AssociationRoutingModule,
    TranslateModule,
    DeviceDetectorModule.forRoot(),
    QRCodeModule,
    ChartModule,
  ],
  exports: [...components]
})
export class AssociationModule {
}

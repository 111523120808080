import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConnectionData } from 'src/app/models/connection-data';
import { Sticker } from 'src/app/models/sticker';
import { LanguageService } from 'src/app/services/language.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'krd-association-connected',
  templateUrl: './association-connected.component.html',
  styleUrls: ['./association-connected.component.scss']
})
export class AssociationConnectedComponent implements OnInit, OnDestroy {
  public connectionData: ConnectionData = null;
  subscriptions : Subscription[] = [];

  public instructionStickers : Sticker[] = [{
    iconSrc: 'assets/icons/video.svg',
    roundNumber: 1,
    circleProgression: 50,
    translationId: 'association.connection.instructions.select',
    message : '',
    size: 3,
    id : 5
  },
  {
    iconSrc: 'assets/icons/go.svg',
    roundNumber: 2,
    circleProgression: 100,
    translationId: 'association.connection.instructions.practice',
    message : '', 
    size: 1,
    id : 6
  }];


  constructor(private socket: WebSocketService,
              private router: Router,
              private translate : TranslateService,
              private langService : LanguageService) { }

  ngOnInit() {
    this.connectionData = this.socket.socketData;
    if (!this.connectionData) {
      this.router.navigate(['/association']);
    }
    this.updateTranslations();
    this.subscriptions.push(this.langService.loadedLangEmitter.subscribe(x => {
      this.updateTranslations();
    }));
  }

  ngOnDestroy() {
    this.socket.disconnect();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private updateTranslations() : void {
    this.instructionStickers = 
      this.instructionStickers.map(x => (
        {
          ...x, 
          message: this.translate.instant(x.translationId)
        }
      ));
  }
}

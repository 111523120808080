import { Component, Input, OnInit } from '@angular/core';
import { ConnectionData } from 'src/app/models/connection-data';

@Component({
  selector: 'krd-pairing-card',
  templateUrl: './pairing-card.component.html',
  styleUrls: ['./pairing-card.component.scss']
})
export class PairingCardComponent {
  @Input() connectionData : ConnectionData;
}

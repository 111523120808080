import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VideoScreenComponent } from './components/video-screen/video-screen.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'association',
    pathMatch: 'full',
  },
  {
    path: 'association',
    loadChildren: () => import('./views/association/association.module').then(m => m.AssociationModule),
  },
  {
    path: 'video',
    component: VideoScreenComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

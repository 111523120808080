<div class="container">
  <!-- MODE NON INTERACTIF -->
  <div
    *ngIf="data && !data.isInteractif"
    class="d-flex container-endurance mt-1 justify-content-center"
  >
    <div class="endurance-box">
      <img
        id="img-endurance-notInteratif"
        src="./assets/img/Entrainement-endurance.svg"
        alt="icone endurance"
        *ngIf="data.equipmentType !== 'treadmill'"
      />
      <img
        id="img-endurance-notInteratif"
        src="./assets/img/Entrainement-Pente.svg"
        alt="icone endurance"
        *ngIf="data.equipmentType === 'treadmill'"
      />
      <img
        id="img-masque-notInteractif"
        src="./assets/img/Entrainement-masque-jauge.svg"
        alt="masque endurance"
      />
      <span
        class="endurance-text"
        *ngIf="data.equipmentType !== 'treadmill' && !equipmentLevel"
        >{{ "gradient-feel.resistance" | translate }}
        <strong>{{ difficulty }}</strong
        >%</span
      >
      <span
        class="endurance-text"
        *ngIf="data.equipmentType === 'treadmill' && !equipmentLevel"
        >{{ "gradient-feel.incline" | translate }}
        <strong>{{ difficulty }}</strong
        >%</span
      >
      <span
        class="endurance-text"
        *ngIf="data.equipmentType === 'treadmill' && equipmentLevel"
        >{{ "gradient-feel.incline" | translate }}
        <strong>{{ equipmentLevel }}</strong></span
      >
      <span
        class="endurance-text"
        *ngIf="data.equipmentType !== 'treadmill' && equipmentLevel"
        >{{ "gradient-feel.resistance" | translate }}
        <strong>{{ equipmentLevel }}</strong></span
      >
    </div>
    <div
      class="progress progress-notInteractif"
      *ngIf="data && !data.isInteractif"
    >
      <div
        class="progress-bar progress-bar-notInteractif"
        role="progressbar"
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="100"
        [style.width]="difficulty + '%'"
        *ngIf="
          data.equipmentType !== 'treadmill' ||
          (data.equipmentType === 'treadmill' && equipmentLevel)
        "
      ></div>
      <div
        class="progress-bar progress-bar-notInteractif"
        role="progressbar"
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="100"
        [style.width]="(difficulty / 15) * 100 + '%'"
        *ngIf="data.equipmentType === 'treadmill' && !equipmentLevel"
      ></div>
    </div>
  </div>

  <!-- MODE INTERATIF -->
  <div
    class="d-flex container-endurance flex-column"
    *ngIf="data && data.isInteractif"
  >
    <div class="d-flex align-items-center ml-4 mt-2 gap-2">
      <img
        id="img-endurance-interactif"
        src="./assets/img/Entrainement-endurance.svg"
        alt="icone endurance"
      />
      <span class="ml-3 resistance-text"
        ><strong>{{ "gradient-feel.difficulty" | translate }}</strong></span
      >
    </div>
    <div
      class="d-flex justify-content-center percent-text"
      *ngIf="difficulty === 100"
    >
      {{ "gradient-feel.original" | translate }} {{ difficulty }}%
    </div>
    <div
      class="d-flex justify-content-center percent-text"
      *ngIf="difficulty < 100"
    >
      {{ "gradient-feel.easier" | translate }} {{ difficulty }}%
    </div>
    <div
      class="d-flex justify-content-center percent-text"
      *ngIf="difficulty > 100"
    >
      {{ "gradient-feel.harder" | translate }} {{ difficulty }}%
    </div>
    <div class="endurance-box-interactif d-flex justify-content-center">
      <div class="img-endurance-box">
        <img
          id="img-masque-interactif"
          src="./assets/img/Entrainement-barre-handicap.svg"
          alt="masque endurance"
        />
        <div class="progress progress-interactif-left">
          <div
            #progressBarLeft
            class="progress-bar progress-bar-interactif-left"
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="0"
            [style.width]="difficulty >= 100 ? '100%' : difficulty + '%'"
          ></div>
        </div>
        <div class="progress progress-interactif-right">
          <div
            #progressBarRight
            class="progress-bar progress-bar-interactif-right"
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="0"
            [style.width]="difficulty > 100 ? difficulty - 100 + '%' : 0 + '%'"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="pairing-card" *ngIf="connectionData">

  <div class="avatar-section">
    <img class="avatar" [src]="connectionData.user_avatar_url" />
  </div>

  <div class="text-content">
    <div class="name">{{ connectionData.username }}</div>
    <div class="paring-details">
      <img src="assets/icons/pair.svg" />
      <div class="label">{{ 'wait-screen.pair' | translate }} {{ connectionData.name }}</div>
    </div>
  </div>

</div>
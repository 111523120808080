<div class="instruction-sticker">
    <div [className]="'flex-box ' + 's-' + sticker.size">
    <div class="disk">
      <div class="disk-content">
        <div 
          [className]="'progression-border ' + 'prog-' + sticker.circleProgression"
        ></div>
        <div class="icon-box">
          <img src="{{sticker.iconSrc}}" />
        </div>
      </div>
      <div class="round-number">{{sticker.roundNumber}}</div>
    </div>
    <div *ngIf="!isLast" class="separator"></div>
  </div>
  <div class="message" [innerHtml]="sticker.message | safeHtml">
  </div>
</div>
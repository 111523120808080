<div class="video_container" id="video-con" (click)="videoResume()">

    <div id="overlay" *ngIf="dataTime < 0">
        <div id="overlay_text">{{ 'video.overlay' | translate }} {{convertSecondtoMinuteSecond(dataTime)}}</div>
    </div>

    <div id="go" [ngClass]="{ 'hide': blinkGo }">
        <div id="go_text">
            GO
        </div>
    </div>

    <div id="lastUnitDistance" [ngClass]="{ 'hide': blinkLastUnitDistance }">

        <img class="imgLastUnitDistance" src="./assets/img/last1km.svg" alt="picto last distance" *ngIf="units === 'metric'">
        <img class="imgLastUnitDistance" src="./assets/img/last1mile.svg" alt="picto last distance" *ngIf="units !== 'metric'">

        <div id="lastUnitDistance_text" *ngIf="units === 'metric'">
            Dernier km
        </div>

        <div id="lastUnitDistance_text" *ngIf="units !== 'metric'">
            Last miles
        </div>

    </div>

    <!-- <div class="corner-ribbon bottom-left sticky shadow">Beta</div> -->

    <div class="lds-spinner" *ngIf="bufferingDetected">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>

    <krd-indicator *ngIf="!loading" id="indicator" [dataIndicator]="data" [modeCoaching]="modeCoaching"
        [data]="videoOption" [currentSpeed]="currentSpeed" [displayMainPower]="displayMainPower" [displayMainSpeed]="displayMainSpeed">
    </krd-indicator>

    <div id="break" *ngIf="!loading"></div>

    <krd-podium *ngIf="!loading && data && videoData.training_mode !=='DISCOVERY'" id="classement" [appColor]="appColor"
        [data]="data" [position]="position" [coachingIntervals]="coachingIntervals" [timeSecond]="timeSecond"
        [distanceFromOther]="distanceFromOther" [hidden]="!rankEnable" [modeCoaching]="modeCoaching"
        [videoOption]="videoOption" [currentSpeed]="currentSpeed" [playerLength] = "playerLength" [style.height]="podiumHeight + 'px'">
    </krd-podium>

    <krd-timeline *ngIf="!loading && dataTime >= 0" [time]="time" [appColor]="appColor" [data]="videoOption"
        [distance]="distance" [timeSecond]="timeSecond" [currentSpeed]="currentSpeed">
    </krd-timeline>

    <krd-loading *ngIf="loading"></krd-loading>

    <!-- <div class="threeDLayer" [style.visibility]="loading ? 'hidden' : 'visible'" #threeDiv></div> -->

    <div id="map" [hidden]="!mapEnable || modeCoaching"></div>

    <krd-chart id="chart" *ngIf="!loading && modeCoaching" [sections]="sections" [coachingIntervals]="coachingIntervals"
        [data]="videoOption" [hidden]="!chartEnable" [timeSecond]="timeSecond">
    </krd-chart>

    <krd-chart id="chart" *ngIf="!loading && !modeCoaching && videoData.equipment_type !== 'rower'"
        [sections]="sections" [coachingIntervals]="coachingIntervals" [data]="videoOption" [hidden]="!chartEnable"
        [timeSecond]="timeSecond">
    </krd-chart>

    <krd-gradient-feel id="gradient" [hidden]="!chartEnable" [data]="videoOption" *ngIf="!loading && modeCoaching">
    </krd-gradient-feel>

    <krd-gradient-feel id="gradient" [hidden]="!chartEnable" [data]="videoOption" *ngIf="!loading && !modeCoaching"
        [ngStyle]="{'top': (mapEnable === true) ? '350px' : '0'}">
    </krd-gradient-feel>
    <div id="video" *ngIf="videoData"></div>
    <!-- <video autoplay playsinline muted *ngIf="videoData" #videoCmp (click)="toggleFullScreen()"
        (loadeddata)="loadedData()" (ended)="videoEnded()"
        (timeupdate)="onTimeUpdate(videoCmp.currentTime * 1000, videoCmp.playbackRate)" (loadedmetadata)="getRatio()">
        <source [src]="url" type="video/mp4">
        {{ 'video.incompatible' | translate }}
    </video> -->

    <div id="playContainer" class="d-flex align-items-center">
        <img id="play" src="./assets/img/play.png" alt="Play button" (click)="videoResume()" *ngIf="showPlay" tabindex="1">
        <div id="playText" class="mt-3" *ngIf="showPlay">{{ 'video.interact' | translate }}</div>
    </div>
</div>
